import axios from "axios";
import parse from "syslog-parse";

export default {
	state: {
		navbar: {
			search: {
				query: null,
				validation: false,
				result: null,
			},
		},
		about: {
			extended: localStorage.getItem("system__about-extended") === "true" ? true : false || false,
			cancellation: true,
			validation: false,
		},
		scheme: {},
		children: {
			extended: localStorage.getItem("system__children-extended") === "true" ? true : false || false,
			list: [],
		},
		zones: [],
		entrances: [],
		tasks: [],
		config: {
			extended: localStorage.getItem("system__config-extended") === "true" ? true : false || false,
			cancellation: true,
			validation: false,
		},
		state: {
			network: {
				type: null,
				status: null,
				indicator: null,
				timer: null,
				countdown: null,
			},
			extended: localStorage.getItem("system__state-extended") === "true" ? true : false || false,
		},
		control: {
			extended: localStorage.getItem("system__control-extended") === "true" ? true : false || false,
			cancellation: true,
			validation: false,
		},
		modes: {
			extended: localStorage.getItem("system__modes-extended") === "true" ? true : false || false,
			cancellation: true,
			validation: false,
		},
		features: {
			extended: localStorage.getItem("system__features-extended") === "true" ? true : false || false,
			cancellation: true,
			validation: false,
		},
		firmware: {
			extended: localStorage.getItem("system__firmware-extended") === "true" ? true : false || false,
			cancellation: true,
			validation: false,
		},
		logs: {
			extended: localStorage.getItem("system__logs-extended") === "true" ? true : false || false,
			cancellation: true,
			validation: false,
		},
		records: {
			extended: localStorage.getItem("system__records-extended") === "true" ? true : false || false,
			search: {
				query: "",
				validation: false,
				result: null,
			},
			backup: null,
		},
		board: {},
		deleting: true,
		socketRate: false,
		backup: {},
	},
	getters: {
		getSystemNavbar: (state) => state.navbar,
		getSystemAbout: (state) => state.about,
		getSystemScheme: (state) => state.scheme,
		getSystemChildren: (state) => state.children,
		getSystemZones: (state) => state.zones,
		getSystemEntrances: (state) => state.entrances,
		getSystemTasks: (state) => state.tasks,
		getSystemConfig: (state) => state.config,
		getSystemState: (state) => state.state,
		getSystemControl: (state) => state.control,
		getSystemModes: (state) => state.modes,
		getSystemFeatures: (state) => state.features,
		getSystemFirmware: (state) => state.firmware,
		getSystemLogs: (state) => state.logs,
		getSystemRecords: (state) => state.records,
		getSystemDeleting: (state) => state.deleting,
	},
	mutations: {
		toggleSystemSubSection(state, payload) {
			state.modes.list.forEach((mode) => {
				if (mode.id === payload.id) mode.extended = !mode.extended;
			});
		},
		toggleSystemSection(state, payload) {
			state[payload].extended = !state[payload].extended;

			localStorage.setItem(`system__${payload}-extended`, state[payload].extended);

			if (payload === "state") {
				state.socketRate = state[payload].extended;
				localStorage.setItem(`system__socket-rate`, state[payload].extended);

				let socket = {
					data: {
						high_rate: state[payload].extended,
					},
				};

				axios
					.put(`${this.state.common.api}/changeRate/${state.about.id}`, socket, this.state.common.headers)
					.then((response) => console.log(response));
			}
		},
		setSystemActiveNavbarItem(state, item) {
			state.navbar.list.forEach((element) => (element.isActive = false));
			state.navbar.list.forEach((element) => {
				if (element.id == item.getAttribute("data-id")) {
					element.isActive = true;
					state.navbar.current = +item.getAttribute("data-id");
					localStorage.setItem(`system__navbar-current`, +item.getAttribute("data-id"));

					if (state.navbar.current === 3) {
						state.socketRate = localStorage.getItem("system__state-extended") === "true" ? true : false;

						if (state.socketRate) {
							localStorage.setItem(`system__socket-rate`, true);

							let socket = {
								data: {
									high_rate: true,
								},
							};

							axios
								.put(`${this.state.common.api}/changeRate/${state.about.id}`, socket, this.state.common.headers)
								.then((response) => console.log(response));
						} else {
							//console.log("Не отправляем ничего");
							return;
						}
					} else {
						if (localStorage.getItem("system__socket-rate") === "true") state.socketRate = true;
						if (localStorage.getItem("system__socket-rate") === "false") state.socketRate = false;

						if (!state.socketRate) {
							//console.log("Не отправляем ничего");
							return;
						} else {
							state.socketRate = false;

							localStorage.setItem(`system__socket-rate`, false);

							let socket = {
								data: {
									high_rate: false,
								},
							};

							//console.log("Отправляем " + socket.data.high_rate);

							axios
								.put(`${this.state.common.api}/changeRate/${state.about.id}`, socket, this.state.common.headers)
								.then((response) => console.log(response));
						}
					}
				}
			});
		},
		setSystemActiveZone(state, payload) {
			state.zones.forEach((zone, index) => {
				if (payload.id !== zone.id) {
					zone.current = false;
					zone.cancellation = true;
					zone.validation = false;
					zone.extended = false;
					zone.regime.extended = false;

					if (state.backup.zones && zone.id === state.backup.zones[index].id) {
						zone.hum = state.backup.zones[index].hum;
						zone.temp = state.backup.zones[index].temp;
						zone.name = state.backup.zones[index].name;
						zone.num = state.backup.zones[index].num;
						zone.device = state.backup.zones[index].device;
						zone.devs = state.backup.zones[index].devs;
						zone.nozzle = state.backup.zones[index].nozzle;
						zone.nozzles = state.backup.zones[index].nozzles;
						zone.status = "default";
						state.backup.zones[index] = {};
					}
				}
			});

			state.zones.forEach((zone) => (zone.id === payload.id ? (zone.current = true) : (zone.current = false)));
			state.deleting = false;
		},
		extendSystemZone(state, payload) {
			state.zones.forEach((zone) => (zone.id === payload.id ? (zone.extended = true) : false));
		},
		collapseSystemZone(state, payload) {
			state.zones.forEach((zone) => {
				if (zone.id === payload.id) zone.extended = !zone.extended;
				else {
					zone.extended = false;
					zone.regime.extended = false;
				}
			});

			state.zones.forEach((zone) => (zone.id === payload.id ? (zone.current = true) : (zone.current = false)));
			state.deleting = false;
		},
		setSystemActiveEntrance(state, payload) {
			state.entrances.forEach((entrance) => {
				if (payload.id !== entrance.id) {
					entrance.current = false;
					entrance.cancellation = true;
					entrance.validation = false;
					entrance.extended = false;
				}
			});
			state.entrances.forEach((entrance) => (entrance.id === payload.id ? (entrance.current = true) : false));
			state.deleting = false;
		},
		extendSystemEntrance(state, payload) {
			state.entrances.forEach((entrance) => {
				if (entrance.id === payload.id) {
					entrance.extended = true;
				}
			});
		},
		collapseSystemEntrance(state, payload) {
			state.entrances.forEach((entrance) => {
				if (entrance.id === payload.id) entrance.extended = false;
			});
		},

		updateSystemNavbar(state, payload) {
			state.navbar = {
				current: localStorage.getItem(`system__navbar-current`)
					? +localStorage.getItem(`system__navbar-current`)
					: 1,
				search: {
					query: null,
					validation: false,
				},
				back: payload.type === "pro_home" ? "Централизованная система" : "Список систем",
			};

			state.navbar.list = [
				{
					id: 1,
					quantity: 6,
					name: "Информация",
					feature: payload.contract.value,
					isActive: state.navbar.current === 1 ? true : false,
				},
				{
					id: 2,
					quantity: payload.type === "pro" ? payload.children.length : payload.tasks.length,
					name: payload.type === "pro" ? "Локальные системы" : "Задачи",
					feature: payload.contract.value,
					isActive: state.navbar.current === 2 ? true : false,
				},
				{
					id: 3,
					quantity: 6,
					name: "Настройка",
					feature: payload.contract.value,
					isActive: state.navbar.current === 3 ? true : false,
				},
			];
		},
		updateSystemAbout(state, payload) {
			state.about.id = payload.id;
			state.about.contract = payload.contract;
			state.about.active_user = payload.active_user;
			state.about.active_srv = payload.active_srv;
			state.about.errors = payload.errors;
			state.about.date = payload.date;
			state.about.mileage = +payload.mileage * 10000;
			state.about.pump_hours = payload.pump_hours;
			state.about.type = payload.type;
			state.about.control_channels = payload.control_channels;
			state.about.system_type = payload.system_type;
			state.about.system_architecture = payload.system_architecture;
			state.about.children = payload.children;

			payload.from_users.forEach((user) => {
				if (user.issuingAccess === true) {
					state.about.from_user = user;
				} else {
					state.about.from_user = payload.from_users[0];
				}
			});

			state.about.name = {
				element: "input",
				name: "name",
				title: "Имя объекта",
				value: payload.name,
				pattern: /^[а-яА-ЯёЁa-zA-Z0-9\s]{2,30}$/,
				status: "default",
				error: false,
				errorText: "Неверный формат",
			};
			state.about.address = {
				element: "input",
				name: "address",
				title: "Адрес объекта",
				value: payload.address,
				status: "default",
				error: false,
				errorText: "Неверный формат",
			};
			state.about.date_start = {
				element: "datepicker",
				name: "date_start",
				title: "Адрес объекта",
				value: payload.date_start,
				pattern: /^[0-9]{2,250}$/,
				status: "valid",
				error: false,
				errorText: "Неверный формат",
			};
			state.about.date_install = {
				element: "datepicker",
				name: "date_install",
				title: "Адрес объекта",
				value: payload.date_install,
				pattern: /^[а-яА-ЯёЁa-zA-Z0-9?!,.\s]{2,250}$/,
				status: "valid",
				error: false,
				errorText: "Неверный формат",
			};
			state.about.date_warranty = {
				element: "datepicker",
				name: "date_warranty",
				title: "Адрес объекта",
				value: payload.date_warranty,
				pattern: /^[а-яА-ЯёЁa-zA-Z0-9?!,.\s]{2,250}$/,
				status: "default",
				error: false,
				errorText: "Неверный формат",
			};
			state.about.date_maintenance_last = {
				element: "datepicker",
				name: "date_maintenance_last",
				value: payload.date_maintenance_last,
				pattern: /^[а-яА-ЯёЁa-zA-Z0-9?!,.\s]{2,250}$/,
				status: "default",
				error: false,
				errorText: "Неверный формат",
			};
			state.about.date_maintenance_plan = {
				element: "datepicker",
				name: "date_maintenance_plan",
				value: payload.date_maintenance_plan,
				pattern: /^[а-яА-ЯёЁa-zA-Z0-9?!,.\s]{2,250}$/,
				status: "default",
				error: false,
				errorText: "Неверный формат",
			};

			state.about.comment = {
				name: "comment",
				value: payload.comment,
				status: "default",
				error: false,
				errorText: "Неверный формат",
			};

			if (payload.type !== "pro_home") {
				state.about.owner = [
					{
						id: 0,
						element: "input",
						type: "text",
						name: "last_name",
						title: state.about.type === "pro" ? "Фамилия диспетчера" : "Фамилия владельца",
						value: state.about.from_user.last_name,
						pattern: /^[а-яА-ЯёЁ]{1,30}$/,
						status: "default",
						error: false,
						errorText: "Неверный формат",
					},
					{
						id: 1,
						element: "input",
						type: "text",
						name: "first_name",
						title: state.about.type === "pro" ? "Имя диспетчера" : "Имя владельца",
						value: state.about.from_user.first_name,
						pattern: /^[а-яА-ЯёЁ]{1,30}$/,
						status: "default",
						error: false,
						errorText: "Неверный формат",
					},
					{
						id: 2,
						element: "input",
						type: "text",
						name: "middle_name",
						title: state.about.type === "pro" ? "Отчество диспетчера" : "Отчество владельца",
						value: state.about.from_user.middle_name,
						pattern: /^[а-яА-ЯёЁ]{1,30}$/,
						status: "default",
						error: false,
						errorText: "Неверный формат",
					},
					{
						id: 3,
						element: "input",
						type: "tel",
						name: "phone",
						title: "Телефон",
						value: state.about.from_user.phone,
						pattern: /^[+]\d{1}\s[(]\d{3}[)]\s\d{3}[-]\d{2}[-]\d{2}$|^7[0-9]{10,10}$/,
						status: "default",
						error: false,
						errorText: "Неверный формат",
					},
					{
						id: 4,
						element: "input",
						type: "email",
						name: "email",
						title: "Электронная почта",
						value: state.about.from_user.email,
						pattern: /^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/,
						status: "default",
						error: false,
						errorText: "Неверный формат",
					},
				];
			}

			state.about.documents = payload.documents;
			state.about.modes = payload.modes;

			state.about.modes.forEach((mode) => {
				if (mode.type === "vacation") {
					state.about.vacation = mode;
				}
			});

			if (payload.state === 0) {
				state.about.state = {
					value: "disabled",
					text: "выключена",
				};
			}

			/* if (payload.state === 1) {
				state.about.state = {
					value: "enabled",
					text: "включена",
				};
			} */

			if (payload.state === 2) {
				state.about.state = {
					value: "error",
					text: "ошибка",
				};
			}

			if (payload.state === 3) {
				state.about.state = {
					value: "active",
					text: "работает",
				};
			}

			if (payload.state === 4) {
				state.about.state = {
					value: "inactive",
					text: "не работает",
				};
			}

			localStorage.setItem("system__system-id", payload.id);
			localStorage.setItem("system__board-id", payload.board.id);
		},
		updateSystemChildren(state, payload) {
			state.children.list = payload.children;

			state.children.list.forEach((child) => {
				if (payload.state === 0) {
					child.state = {
						value: "disabled",
						text: "выключена",
					};
				}

				if (payload.state === 1) {
					child.state = {
						value: "enabled",
						text: "включена",
					};
				}

				if (payload.state === 2) {
					child.state = {
						value: "error",
						text: "ошибка",
					};
				}

				if (payload.state === 3) {
					child.state = {
						value: "active",
						text: "работает",
					};
				}

				if (payload.state === 4) {
					child.state = {
						value: "inactive",
						text: "не работает",
					};
				}
			});
		},
		updateSystemZones(state, payload) {
			state.zones = JSON.parse(JSON.stringify(payload.zones));

			state.zones.forEach((zone) => {
				zone.level = "srv";
				zone.system_architecture = payload.system_architecture;

				zone.name = {
					value: JSON.parse(JSON.stringify(zone.name)),
					status: "default",
				};

				zone.num = {
					type: "simple",
					name: "num",
					val: zone.num,
					value: `Зона ${zone.num}`,
					status: "default",
					error: false,
					errorMessage: "Неверный формат",
					categories: [],
				};

				zone.programs.forEach((program) => {
					program.periods.forEach((period) => {
						let start = period.start.split(":", 2);

						period.start = {
							hour: +start[0],
							minute: +start[1],
						};
					});

					let days = [
						{ num: 1, name: "ПН", activity: false },
						{ num: 2, name: "ВТ", activity: false },
						{ num: 3, name: "СР", activity: false },
						{ num: 4, name: "ЧТ", activity: false },
						{ num: 5, name: "ПТ", activity: false },
						{ num: 6, name: "СБ", activity: false },
						{ num: 7, name: "ВС", activity: false },
					];

					program.days = days.map((elem) => {
						elem.activity = false;
						let programDay = program.days.find((d) => elem.num == d);

						if (programDay) elem.activity = true;

						return elem;
					});
				});

				zone.regime = {
					extended: false,
					name: "program",
					type: "weekdays",
					list: {
						value: "Будни",
						categories: [],
					},
					channels: payload.control_channels.filter((channel) => zone[channel]),
					channel: payload.control_channels[0],
				};

				zone.regime.programs = zone.programs.filter((item) => item.channel === zone.regime.channel);
				zone.regime.program = zone.programs.find((item) => item.channel === zone.regime.channel);
				zone.regime.id = zone.regime.program.id;

				zone.regime.list.categories = zone.regime.programs.map((item) => {
					let obj = {
						id: item.id,
						name: item.type,
						title: item.name,
					};

					return obj;
				});

				zone.regime.active_srv = zone.regime.program.active_srv;

				zone.regime.periods = zone.regime.program.periods;
				zone.regime.days = zone.regime.program.days;

				zone.regime.periods.forEach((period) => {
					period.status = "default";

					if (zone.regime.channel === "hum") period.unit = "%";
					if (zone.regime.channel === "temp") period.unit = "°";
					if (zone.regime.channel === "carb") period.unit = "PPM";
				});

				payload.control_channels.forEach((channel) => {
					if (zone[channel] !== null) {
						zone[channel].status = "default";
						zone[channel].control = false;

						zone[channel].min = {
							value: zone[channel].min,
							name: "min",
							status: "default",
							limit: payload.board[`${[channel]}_min`],
						};

						zone[channel].max = {
							value: zone[channel].max,
							name: "max",
							status: "default",
							limit: payload.board[`${[channel]}_max`],
						};

						zone[channel].gys = {
							value: zone[channel].gys,
							name: "gys",
							status: "default",
							limit: payload.board[`gys_${[channel]}`],
						};

						if (channel === "hum") {
							zone.hum.unit = "%";
							zone.hum.default = 45;

							zone.hum.t_open = {
								value: zone.hum.t_open,
								name: "t_open",
								status: "default",
							};

							zone.hum.t_close = {
								value: zone.hum.t_close,
								name: "t_close",
								status: "default",
							};

							let device = {
								dropdown: {
									name: "device",
									value: null,
									alias: null,
									status: "default",
									categories: [
										{
											id: 1,
											name: "s",
											title: "Датчик",
										},
										{
											id: 2,
											name: "gt",
											title: "Гигростат с тачсрином",
										},
										{
											id: 3,
											name: "gw",
											title: "Гигростат с колесом",
										},
									],
								},
								input: {
									name: "address",
									value: null,
									pattern: /^[а-яА-ЯёЁa-zA-Z0-9?!,.\s]{2,250}$/,
									status: "default",
									error: false,
									errorMessage: "Неверный формат",
								},
								button: {
									value: "add",
									disabled: true,
								},
							};

							let nozzle = {
								dropdown: {
									name: "nozzle",
									value: null,
									alias: null,
									status: "default",
									categories: [
										{
											id: 1,
											name: "01L",
											title: "01L",
										},
										{
											id: 2,
											name: "02L",
											title: "02L",
										},
									],
								},
								input: {
									name: "quan",
									value: null,
									pattern: /^[0-9]{1,5}$/,
									status: "default",
									error: false,
									errorMessage: "Неверный формат",
								},
								button: {
									value: "add",
									disabled: true,
								},
							};

							zone.device = JSON.parse(JSON.stringify(device));
							zone.nozzle = JSON.parse(JSON.stringify(nozzle));
						}

						if (channel === "temp") {
							zone.temp.unit = "°";
							zone.temp.default = 22;
						}

						if (channel === "carb") {
							zone.carb.unit = "PPM";
							zone.carb.default = 600;
						}
					}
				});

				if (zone.errors !== null && zone.errors.length) {
					zone.errors.forEach((element) => {
						if (element.code === 50) {
							zone.devs.forEach((dev) => {
								if (dev.id === element.dev_id) {
									element.text = `Нет связи с датчиком (${dev.type}#${dev.addr})`;
								}
							});
						}

						if (element.code === 51) element.text = "Ошибка датчика влажности";
						if (element.code === 52) element.text = "Ошибка датчика CO2";
						if (element.code === 53) element.text = "Ошибка часов";
						if (element.code === 54) element.text = "Ошибка сенсора экрана";
						if (element.code === 55) element.text = "Ошибка внешней flash";
					});
				}

				zone.current = false;
				zone.extended = false;
				zone.cancellation = true;
				zone.validation = false;
			});

			state.deleting = true;
		},
		updateSystemEntrances(state, payload) {
			state.entrances = JSON.parse(JSON.stringify(payload.entrances));
		},
		updateSystemTasks(state, payload) {
			state.tasks = payload.data;
		},
		updateSystemBoard(state, payload) {
			state.board = payload.board;
		},
		updateSystemConfig(state, payload) {
			if (payload.state === 0) {
				state.config.state = {
					value: "disabled",
					text: "выключена",
				};
			}

			/* if (payload.state === 1) {
				state.config.state = {
					value: "enabled",
					text: "включена",
				};
			} */

			if (payload.state === 2) {
				state.config.state = {
					value: "error",
					text: "ошибка",
				};
			}

			if (payload.state === 3) {
				state.config.state = {
					value: "active",
					text: "работает",
				};
			}

			if (payload.state === 4) {
				state.config.state = {
					value: "inactive",
					text: "не работает",
				};
			}

			state.config.pump_hours = payload.pump_hours;
			state.config.permission_for_work = payload.permission_for_work;
			state.config.params = [
				{
					id: 0,
					element: "dropdown",
					type: "simple",
					name: "system_type",
					title: "Тип системы",
					value: payload.system_type,
					error: null,
					status: "default",
					categories: [
						{
							id: 1,
							name: "1",
							title: "Uno",
						},
						{
							id: 2,
							name: "2",
							title: "Multi",
						},
						{
							id: 3,
							name: "3",
							title: "Profi",
						},
					],
				},
				{
					id: 1,
					element: "dropdown",
					type: "simple",
					name: "system_architecture",
					title: "Архитектура системы",
					value: payload.system_architecture,
					error: null,
					status: "default",
					categories: [
						{
							id: 1,
							name: "1",
							title: "Лучевая",
						},
						{
							id: 2,
							name: "2",
							title: "Кольцевая",
						},
						{
							id: 3,
							name: "4",
							title: "Комбинированная",
						},
					],
				},
				{
					id: 2,
					element: "dropdown",
					type: "simple",
					name: "pump_model",
					title: "Модель насоса",
					value: payload.pump_model,
					error: null,
					status: "default",
					categories: [
						{
							id: 1,
							name: "1",
							title: "Pro max",
						},
						{
							id: 2,
							name: "2",
							title: "Pro",
						},
					],
				},
				{
					id: 3,
					element: "dropdown",
					type: "simple",
					name: "pump_block",
					title: "Насосный блок",
					value: payload.pump_block,
					error: null,
					status: "default",
					categories: [
						{
							id: 1,
							name: "1",
							title: "WetPRO-18",
						},
						{
							id: 2,
							name: "2",
							title: "WetPRO-20",
						},
					],
				},
				{
					id: 4,
					element: "dropdown",
					type: "simple",
					name: "water_prepare",
					title: "Водоподготовка",
					value: payload.water_prepare,
					error: null,
					status: "default",
					categories: [
						{
							id: 1,
							name: "1",
							title: "Clean-018",
						},
						{
							id: 2,
							name: "2",
							title: "Clean-020",
						},
					],
				},
				{
					id: 5,
					element: "dropdown",
					type: "simple",
					name: "tz",
					title: "Часовой пояс",
					alias: payload.tz,
					value: null,
					error: null,
					status: "default",
					categories: [
						{
							id: 1,
							name: "2",
							title: "MSK–1 (UTC+2)",
						},
						{
							id: 2,
							name: "3",
							title: "MSK (UTC+3)",
						},
						{
							id: 3,
							name: "4",
							title: "MSK+1 (UTC+4)",
						},
						{
							id: 4,
							name: "5",
							title: "MSK+2 (UTC+5)",
						},
						{
							id: 5,
							name: "6",
							title: "MSK+3 (UTC+6)",
						},
						{
							id: 6,
							name: "7",
							title: "MSK+4 (UTC+7)",
						},
						{
							id: 7,
							name: "8",
							title: "MSK+5 (UTC+8)",
						},
						{
							id: 8,
							name: "9",
							title: "MSK+6 (UTC+9)",
						},
						{
							id: 9,
							name: "10",
							title: "MSK+7 (UTC+10)",
						},
						{
							id: 10,
							name: "11",
							title: "MSK+8 (UTC+11)",
						},
						{
							id: 11,
							name: "12",
							title: "MSK+9 (UTC+12)",
						},
					],
				},
			];

			state.config.params.forEach((item) => {
				if (item.name === "tz") {
					let elem = item.categories.find((category) => category.name == item.alias);
					if (elem) item.value = elem.title;
				}
			});
		},
		updateSystemState(state, payload) {
			let formatTime = (function () {
				function num(val) {
					val = Math.floor(val);
					return val < 10 ? "0" + val : val;
				}

				return function (ms) {
					let sec = ms / 1000,
						hours = (sec / 3600) % 24,
						minutes = (sec / 60) % 60,
						seconds = sec % 60;

					return num(hours) + ":" + num(minutes) + ":" + num(seconds);
				};
			})();

			let zones = JSON.parse(JSON.stringify(payload.zones));

			state.state.p_in = payload.p_in;
			state.state.p_out = payload.p_out;
			state.state.valve_in = payload.valve_in;
			state.state.valve_drain = payload.valve_drain;
			state.state.pump_rpm = payload.pump_rpm;
			state.state.active_srv = payload.active_srv;
			state.state.status = payload.status;
			state.state.timer = payload.timer;
			state.state.errors = payload.errors;
			state.state.zones = zones.sort((a, b) => a.num - b.num);
			state.state.timer = state.state.timer < 0 ? 0 : state.state.timer;
			state.state.timer = formatTime(state.state.timer * 1000);
			state.state.zones.forEach((zone) => {
				zone.timer_work = formatTime(zone.timer_work * 1000);
				zone.timer_pause = formatTime(zone.timer_pause * 1000);
			});

			if (state.state.status === 0) state.state.status = "Ожидание";
			if (state.state.status === 1) state.state.status = "Набор давления";
			if (state.state.status === 2) state.state.status = "Активный режим";
			if (state.state.status === 3) state.state.status = "Снижение давления";
			if (state.state.status === 4) state.state.status = "Сброс воды";
			if (state.state.status === 5) state.state.status = "Цикл завершён";
			if (state.state.status === 10) state.state.status = "Пауза";
			if (state.state.status === 11) state.state.status = "Ручной режим";
			if (state.state.status === 101) state.state.status = "Недостаточное входное давление";
			if (state.state.status === 102) state.state.status = "Высокое входное давление";
			if (state.state.status === 103) state.state.status = "Не удалось набрать выходное давление";
			if (state.state.status === 104) state.state.status = "Не удалось поддерживать выходное давление";
			if (state.state.status === 105) state.state.status = "Превышено выходное давление";
			if (state.state.status === 106) state.state.status = "Не удалось снизить давление";
			if (state.state.status === 107) state.state.status = "Не удалось сбросить воду";
			if (state.state.status === 108) state.state.status = "Ошибка связи с насосом";
			if (state.state.status === 109) state.state.status = "Авария, утечка";

			if (state.state.errors !== null) {
				state.state.errors.forEach((element) => {
					if (element.code === 1) element.text = "Недостаточный уровень входного давления";
					if (element.code === 4) element.text = "Протечка (датчик протечки)";
					if (element.code === 7) element.text = "Ошибка набора давления";
					if (element.code === 8) element.text = "Падение давления в рабочем цикле";
					if (element.code === 9) element.text = "Ошибка старта насоса";
					if (element.code === 16) element.text = "Превышение уровня входного давления";
					if (element.code === 32) element.text = "Превышение уровня выходного давления";
					if (element.code === 100) element.text = "RS485 соединение с драйвером потеряно";
					if (element.code === 110) element.text = "Соединение с датчиком потеряно";
					if (element.code === 111) element.text = "Показания с датчиков/гигростатов некорректные";
					if (element.code === 400) element.text = "Сервер не отвечает";
				});
			}

			if (state.state.network) {
				if (state.state.network.type === "request") {
					state.state.network.status = 2;
					state.state.network.type = null;
				} else {
					state.state.network.status = 1;
					state.state.network.indicator = "blink";
				}

				setTimeout(() => (state.state.network.indicator = "default"), 1000);
				clearInterval(state.state.network.timer);

				state.state.network.timer = null;
				state.state.network.countdown = 30;

				state.state.network.timer = setInterval(() => {
					state.state.network.countdown--;

					if (state.state.network.countdown <= 1) {
						state.state.network.countdown = 30;
						state.state.network.status = 0;
						state.state.network.indicator = "off";

						setTimeout(() => {
							clearInterval(state.state.network.timer);
							state.state.network.timer = null;
						}, 1000);
					}
				}, 1000);
			}
		},
		updateSystemControl(state, payload) {
			let control = JSON.parse(JSON.stringify(payload));

			state.control.manual_control = control.manual_control;
			state.control.valve_drain = control.valve_drain;
			state.control.valve_in = control.valve_in;
			state.control.pump = control.pump;
			state.control.driver = control.driver;
			state.control.zones = control.zones.sort((a, b) => a.num - b.num);
		},
		updateSystemFeatures(state, payload) {
			state.features.params = [
				{
					id: 0,
					name: "p_in_min",
					title: "Входное давление min (бар)",
					value: payload.board.p_in_min,
					error: null,
					status: "default",
				},
				{
					id: 1,
					name: "p_in_max",
					title: "Входное давление max (бар)",
					value: payload.board.p_in_max,
					error: null,
					status: "default",
				},
				{
					id: 2,
					name: "p_out_min",
					title: "Выходное давление min (бар)",
					value: payload.board.p_out_min,
					error: null,
					status: "default",
				},
				{
					id: 3,
					name: "p_out_nom",
					title: "Выходное давление nom (бар)",
					value: payload.board.p_out_nom,
					error: null,
					status: "default",
				},
				{
					id: 4,
					name: "p_out_max",
					title: "Выходное давление max (бар)",
					value: payload.board.p_out_max,
					error: null,
					status: "default",
				},
				{
					id: 5,
					name: "p_precharge",
					title: "P предварительное (бар)",
					value: payload.board.p_precharge,
					error: null,
					status: "default",
				},
				{
					id: 6,
					name: "precharge_timeout",
					title: "Таймаут набора давления (с)",
					value: payload.board.precharge_timeout,
					error: null,
					status: "default",
				},
				{
					id: 7,
					name: "p_delay",
					title: "Задержка контроля давления (с)",
					value: payload.board.p_delay,
					error: null,
					status: "default",
				},
				{
					id: 8,
					name: "p_timeout",
					title: "Таймаут ошибки давления (c)",
					value: payload.board.p_timeout,
					error: null,
					status: "default",
				},
				{
					id: 9,
					name: "pump_rpm_max",
					title: "Vmax (об/мин)",
					value: payload.board.pump_rpm_max,
					error: null,
					status: "default",
				},
				{
					id: 10,
					name: "Kp",
					title: "Регулятор давления Кп",
					value: payload.board.Kp,
					error: null,
					status: "default",
				},
				{
					id: 11,
					name: "Ki",
					title: "Регулятор давления Ки",
					value: payload.board.Ki,
					error: null,
					status: "default",
				},
				{
					id: 12,
					name: "Kd",
					title: "Регулятор давления Кд",
					value: payload.board.Kd,
					error: null,
					status: "default",
				},
				{
					id: 13,
					name: "p_drain",
					title: "Давление сброса",
					value: payload.board.p_drain,
					error: null,
					status: "default",
				},
				{
					id: 14,
					name: "discharge_timeout",
					title: "Таймаут снижения давления (с)",
					value: payload.board.discharge_timeout,
					error: null,
					status: "default",
				},
				{
					id: 15,
					name: "t_drain",
					title: "Время сброса",
					value: payload.board.t_drain,
					error: null,
					status: "default",
				},
				{
					id: 16,
					name: "t_open",
					title: "Длительность работы",
					value: payload.board.t_open,
					error: null,
					status: "default",
					invisible: payload.system_architecture === "Кольцевая" ? true : false,
				},
				{
					id: 17,
					name: "t_close",
					title: "Длительность паузы",
					value: payload.board.t_close,
					error: null,
					status: "default",
					invisible: payload.system_architecture === "Кольцевая" ? true : false,
				},
				{
					id: 18,
					name: "hum_min",
					title: "Минимальная влажность зон",
					value: payload.board.hum_min,
					error: null,
					status: "default",
				},
				{
					id: 19,
					name: "hum_max",
					title: "Максимальная влажность зон",
					value: payload.board.hum_max,
					error: null,
					status: "default",
				},
				{
					id: 20,
					name: "temp_min",
					title: "Минимальная температура зон",
					value: payload.board.temp_min,
					error: null,
					status: "default",
				},
				{
					id: 21,
					name: "temp_max",
					title: "Максимальная температура зон",
					value: payload.board.temp_max,
					error: null,
					status: "default",
				},
				{
					id: 22,
					name: "carb_min",
					title: "Минимальный уровень CO2 зон",
					value: payload.board.carb_min,
					error: null,
					status: "default",
				},
				{
					id: 23,
					name: "carb_max",
					title: "Максимальный уровень CO2 зон",
					value: payload.board.carb_max,
					error: null,
					status: "default",
				},
				{
					id: 24,
					name: "gys_carb",
					title: "Гистерезис управления концентрацией СО2",
					value: payload.board.gys_carb,
					error: null,
					status: "default",
				},
				{
					id: 25,
					name: "gys_hum",
					title: "Гистерезис управления влажностью",
					value: payload.board.gys_hum,
					error: null,
					status: "default",
				},
				{
					id: 26,
					name: "gys_temp",
					title: "Гистерезис управления температурой",
					value: payload.board.gys_temp,
					error: null,
					status: "default",
				},
				{
					id: 27,
					name: "t_no_motion",
					title: "Время отсутствия движения (с)",
					value: payload.board.t_no_motion,
					error: null,
					status: "default",
				},
				{
					id: 28,
					dropdown: true,
					type: "simple",
					name: "low_p_kf",
					title: "Модель датчика низкого давления",
					value: null,
					alias: payload.board.low_p_kf,
					error: null,
					status: "default",
					categories: [
						{
							id: 1,
							name: "10",
							title: "0-10 бар",
						},
						{
							id: 2,
							name: "100",
							title: "0-100 бар",
						},
						{
							id: 3,
							name: "200",
							title: "0-200 бар",
						},
					],
				},
				{
					id: 29,
					dropdown: true,
					type: "simple",
					name: "high_p_kf",
					title: "Модель датчика высокого давления",
					value: null,
					alias: payload.board.high_p_kf,
					error: null,
					status: "default",
					categories: [
						{
							id: 1,
							name: "10",
							title: "0-10 бар",
						},
						{
							id: 2,
							name: "100",
							title: "0-100 бар",
						},
						{
							id: 3,
							name: "200",
							title: "0-200 бар",
						},
					],
				},
				{
					id: 30,
					name: "t_deadzone",
					title: "Время дельты для чувствительности срабатывания алгоритма защиты от протечек",
					value: payload.board.t_deadzone,
					error: null,
					status: "default",
				},
			];

			state.features.params.forEach((item) => {
				if (item.dropdown) {
					let elem = item.categories.find((category) => category.name == item.alias);
					if (elem) item.value = elem.title;
				}
			});
		},
		updateSystemFirmware(state, payload) {
			if (payload.socket) {
				if (payload.status === "checked") {
					state.firmware.processing = true;
					state.firmware.warning = `
						Файл прошивки загружен и проверен
					`;
				}
				if (payload.status === "updated") {
					this.commit("handleError", "Прошивка успешно завершена");
					let fm = state.firmware.firmwares.find((fm) => fm.id === +payload.firmwareID);

					state.firmware.processing = false;
					state.firmware.warning = null;
					state.firmware.file = null;
					state.firmware.version = fm ? fm.version : "";

					state.firmware.list = {
						id: null,
						element: "dropdown",
						type: "simple",
						name: "firmware_list",
						value: null,
						error: null,
						status: "default",
						categories: [],
					};

					if (state.firmware.firmwares.length) {
						state.firmware.firmwares.forEach((item) => {
							let obj = {
								name: item.id,
								title: `Прошивка ${item.version}`,
							};

							state.firmware.list.categories.push(obj);
						});
					}
				}
				if (payload.status === "error") {
					this.commit("handleError", "Ошибка обновления прошивки");
					state.firmware.processing = false;
					state.firmware.warning = null;
					state.firmware.file = null;

					state.firmware.list = {
						id: null,
						element: "dropdown",
						type: "simple",
						name: "firmware_list",
						value: null,
						error: null,
						status: "default",
						categories: [],
					};

					if (state.firmware.firmwares.length) {
						state.firmware.firmwares.forEach((item) => {
							let obj = {
								name: item.id,
								title: `Прошивка ${item.version}`,
							};

							state.firmware.list.categories.push(obj);
						});
					}
				}
			} else {
				if (payload.board) {
					let board = JSON.parse(JSON.stringify(payload.board));
					state.firmware.firmwares = board.firmwares;
					state.firmware.version = board.version;
				} else {
					state.firmware.firmwares = payload.data;
				}

				state.firmware.processing = false;
				state.firmware.warning = null;
				state.firmware.file = null;

				state.firmware.list = {
					id: null,
					element: "dropdown",
					type: "simple",
					name: "firmware_list",
					value: null,
					error: null,
					status: "default",
					categories: [],
				};

				if (state.firmware.firmwares.length) {
					state.firmware.firmwares.forEach((item) => {
						let obj = {
							name: item.id,
							title: `Прошивка ${item.version}`,
						};

						state.firmware.list.categories.push(obj);
					});
				}
			}
		},
		updateSystemLogs(state, payload) {
			if (payload.length) {
				state.logs.list = payload.slice(0, 50);
				state.logs.processing = false;
				state.logs.warning = null;
			}

			state.logs.date_logs_start = {
				element: "datepicker",
				name: "date_logs_start",
				value: null,
				pattern: /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)[0-9]{2}$/,
				status: "default",
				error: false,
				errorText: "Неверный формат",
			};

			state.logs.date_logs_end = {
				element: "datepicker",
				name: "date_logs_end",
				value: null,
				pattern: /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)[0-9]{2}$/,
				status: "default",
				error: false,
				errorText: "Неверный формат",
			};
		},
		updateSystemRecords(state, payload) {
			let categories = [];

			if (payload) {
				let data = payload.data;

				categories = data.map((item, ind) => {
					let obj = {
						id: ind,
						name: item.name,
						title: item.file,
					};

					return obj;
				});
			}

			state.records.list = {
				type: "simple",
				name: "records_list",
				value: null,
				error: null,
				status: "default",
				categories: categories,
			};

			state.records.filter = {
				type: "simple",
				name: "records_filter",
				value: "ALL",
				error: null,
				status: "default",
				categories: [
					{
						id: 1,
						name: "all",
						title: "ALL",
					},
					{
						id: 2,
						name: "emerg",
						title: "EMERG",
					},
					{
						id: 3,
						name: "crit",
						title: "CRIT",
					},
					{
						id: 4,
						name: "alert",
						title: "ALERT",
					},
					{
						id: 5,
						name: "debug",
						title: "DEBUG",
					},
					{
						id: 6,
						name: "notice",
						title: "NOTICE",
					},
					{
						id: 7,
						name: "info",
						title: "INFO",
					},
				],
			};

			state.records.file = null;
			state.records.lines = [];

			/* if (payload.length) {
				state.logs.list = payload.slice(0, 50);
				state.logs.processing = false;
				state.logs.warning = null;
			}

			state.logs.date_logs_start = {
				element: "datepicker",
				name: "date_logs_start",
				value: null,
				pattern: /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)[0-9]{2}$/,
				status: "default",
				error: false,
				errorText: "Неверный формат",
			};

			state.logs.date_logs_end = {
				element: "datepicker",
				name: "date_logs_end",
				value: null,
				pattern: /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)[0-9]{2}$/,
				status: "default",
				error: false,
				errorText: "Неверный формат",
			}; */
		},
		updateSystemModes(state, payload) {
			state.modes.list = payload.modes;

			state.modes.list.forEach((mode) => {
				//mode.extended = false;

				let days = [
					{ num: 1, name: "ПН", activity: false },
					{ num: 2, name: "ВТ", activity: false },
					{ num: 3, name: "СР", activity: false },
					{ num: 4, name: "ЧТ", activity: false },
					{ num: 5, name: "ПТ", activity: false },
					{ num: 6, name: "СБ", activity: false },
					{ num: 7, name: "ВС", activity: false },
				];

				if (!mode.days) mode.days = [1, 2];

				mode.days = days.map((elem) => {
					elem.activity = false;
					let modeDay = mode.days.find((d) => elem.num == d);

					if (modeDay) elem.activity = true;

					return elem;
				});

				if (!mode.start) mode.start = ["00:00"];

				mode.start = mode.start.map((item, ind) => {
					let start = item.split(":", 2);
					let obj = {
						id: ind,
						hour: +start[0],
						minute: +start[1],
					};

					return obj;
				});

				mode.duration = {
					name: "duration",
					title: "Длительность",
					value: mode.duration,
					error: null,
					status: "default",
				};

				if (mode.t_deadzone) {
					mode.t_deadzone = {
						name: "t_deadzone",
						title: "Длительность",
						value: mode.t_deadzone,
						error: null,
						status: "default",
					};
				}

				if (mode.zones && mode.zones.length) {
					mode.zones.forEach((zone) => {
						if (zone.t_drain) {
							zone.t_drain = {
								name: "t_drain",
								title: "Длительность",
								value: zone.t_drain,
								error: null,
								status: "default",
							};
						}
					});
				}
			});
		},

		handleSystemChanges(state, payload) {
			function isEmpty(obj) {
				for (let key in obj) {
					return false;
				}
				return true;
			}

			if (payload.event === "change" || payload.event === "paste" || payload.event === "input") {
				if (payload.section === "about") {
					if (isEmpty(state.backup.about)) {
						state.backup.about = JSON.parse(JSON.stringify(state.about));
					}

					state.about.cancellation = false;
					state.about.validation = true;

					if (payload.name === "name" || payload.name === "address") {
						if (state.about[payload.name].name === payload.name) {
							state.about[payload.name].value = payload.value;
							state.about.extended = true;

							if (payload.value.match(this.state.common.patterns[payload.name])) {
								state.about[payload.name].status = "valid";
								state.about[payload.name].error = false;
							} else {
								state.about[payload.name].status = "invalid";
								state.about[payload.name].error = true;
							}
						}
					} else if (
						payload.name === "date_start" ||
						payload.name === "date_install" ||
						payload.name === "date_warranty" ||
						payload.name === "date_maintenance_last" ||
						payload.name === "date_maintenance_plan"
					) {
						if (state.about[payload.name].name === payload.name) {
							state.about[payload.name].value = Math.round(payload.value);

							if (payload.name === "date_maintenance_last" || payload.name === "date_maintenance_plan")
								state.about.extended = true;

							if (String(payload.value).match(state.about[payload.name].pattern)) {
								state.about[payload.name].status = "valid";
								state.about[payload.name].error = false;
							} else {
								state.about[payload.name].status = "invalid";
								state.about[payload.name].error = true;
							}
						}
					} else if (payload.name === "comment") {
						state.about[payload.name].value = payload.value;
						state.about[payload.name].changed = true;

						/* if (payload.value.match(this.state.common.patterns[payload.name])) {
							state.about[payload.name].status = "valid";
							state.about[payload.name].error = false;
						} else {
							state.about[payload.name].status = "invalid";
							state.about[payload.name].error = true;
						} */
					} else if (payload.name === "vacation") {
						state.about[payload.name].active_srv = payload.value;
						state.about[payload.name].changed = true;
					} else {
						state.about.owner.forEach((param) => {
							if (param.name === payload.name) {
								param.value = payload.value;

								if (payload.value.match(param.pattern)) {
									param.status = "valid";
									param.error = false;
								} else {
									param.status = "invalid";
									param.error = true;
								}
							}
						});
					}

					state.about.owner.forEach((param) => {
						if (
							param.status === "invalid" ||
							state.about.name.status === "invalid" ||
							state.about.address.status === "invalid" ||
							state.about.comment.status === "invalid"
						) {
							state.about.validation = false;
						}
					});
				}

				if (payload.section === "config") {
					if (isEmpty(state.backup.config)) {
						state.backup.config = JSON.parse(JSON.stringify(state.config));
					}

					if (payload.name === "permission_for_work") {
						state.config[payload.name] = payload.value;
						state.config.extended = true;

						state.config.cancellation = false;
						state.config.validation = true;
					} else {
						state.config.params.forEach((param) => {
							if (param.name === payload.name) {
								param.value = payload.value;
								param.alias = payload.alias;
								param.status = "valid";

								if (payload.name in state.about) state.about[payload.name] = payload.value;
							}
						});

						state.config.cancellation = false;
						state.config.validation = true;

						state.config.params.forEach((param) => {
							if (param.status === "invalid") {
								state.config.validation = false;
							}
						});
					}
				}

				if (payload.section === "control") {
					if (isEmpty(state.backup.control)) {
						state.backup.control = JSON.parse(JSON.stringify(state.control));
					}

					if (payload.name === "manual_control") state.control.manual_control = payload.value;
					if (payload.name === "pump") state.control.pump = payload.value;
					if (payload.name === "driver") state.control.driver = payload.value;
					if (payload.name === "valve_drain") state.control.valve_drain = payload.value;
					if (payload.name === "valve_in") state.control.valve_in = payload.value;

					state.control.zones.forEach((zone) => {
						if (zone.id === payload.id) {
							zone[payload.param].activity = payload.value;
							zone.changed = true;
						}
					});

					state.control.cancellation = false;
					state.control.validation = true;
				}

				if (payload.section === "modes") {
					if (isEmpty(state.backup.modes)) {
						state.backup.modes = JSON.parse(JSON.stringify(state.modes));
					}

					state.modes.list.forEach((mode) => {
						if (mode.id === payload.id) {
							mode.changed = true;

							console.log(payload);

							if (payload.name === "add_mode_start") {
								mode.start.push({
									id: mode.start.length,
									hour: 9,
									minute: 0,
								});

								state.modes.cancellation = false;
								state.modes.validation = true;
							}

							if (payload.name === "delete_mode_start") {
								mode.start = mode.start.filter((item) => item.id !== payload.startId);

								mode.start.forEach((item, index) => (item.id = index));

								state.modes.cancellation = false;
								state.modes.validation = true;
							}

							if (payload.name === "active_srv") {
								mode.active_srv = payload.value;
								mode.extended = true;

								state.modes.cancellation = false;
								state.modes.validation = true;
							}

							if (payload.name === "days") {
								mode.days.forEach((day) => {
									if (day.num === payload.day) day.activity = payload.value;
								});

								state.modes.cancellation = false;
								state.modes.validation = true;
							}

							if (payload.type === "time") {
								mode[payload.stage].forEach((item) => {
									if (item.id === payload.startID) item[payload.unit] = payload.value;
								});

								state.modes.cancellation = false;
								state.modes.validation = true;
							}

							if (payload.name === "duration") {
								mode.duration.value = payload.value;

								if (this.state.common.patterns[payload.name].test(payload.value)) {
									mode.duration.status = "valid";
									mode.duration.error = null;
								} else {
									mode.duration.status = "invalid";
									mode.duration.error = "Неверный формат";
								}

								state.modes.cancellation = false;
								state.modes.validation = true;

								if (mode.duration.status === "invalid") {
									state.modes.validation = false;
								} else {
									mode.duration.value = +payload.value;
								}
							}

							if (payload.name === "zone_active") {
								mode.zones.forEach((zone) => {
									if (zone.id === payload.zone) zone.active = payload.value;
								});

								state.modes.cancellation = false;
								state.modes.validation = true;
							}

							if (payload.name === "t_drain") {
								mode.zones.forEach((zone) => {
									if (zone.id === payload.zone) {
										zone[payload.name].value = payload.value;

										if (this.state.common.patterns[payload.name].test(payload.value)) {
											zone[payload.name].status = "valid";
											zone[payload.name].error = null;
										} else {
											zone[payload.name].status = "invalid";
											zone[payload.name].error = "Неверный формат";
										}

										state.modes.cancellation = false;
										state.modes.validation = true;

										if (zone[payload.name].status === "invalid") {
											state.modes.validation = false;
										}
									}
								});
							}

							if (payload.name === "commissioning_start") {
								mode.commissioning.active = true;
								state.modes.cancellation = false;
								state.modes.validation = true;
							}

							if (payload.name === "commissioning_stop") {
								mode.commissioning.active = false;
								state.modes.cancellation = false;
								state.modes.validation = true;
							}
						}
					});
				}

				if (payload.section === "firmware") {
					if (isEmpty(state.backup.firmware)) {
						state.backup.firmware = JSON.parse(JSON.stringify(state.firmware));
					}

					if (payload.name === "file") state.firmware.file = payload.value;
					if (payload.name === "firmware_list") {
						state.firmware.list.id = payload.id;
						state.firmware.list.value = payload.value;
						state.firmware.validation = true;
					}

					state.firmware.cancellation = false;
				}

				if (payload.section === "features") {
					if (isEmpty(state.backup.features)) {
						state.backup.features = JSON.parse(JSON.stringify(state.features));
					}

					state.features.params.forEach((param) => {
						if (payload.element === "dropdown") {
							if (param.name === payload.name) {
								param.value = payload.value;
								param.alias = payload.alias;
								param.status = "valid";
							}
						} else if (payload.element === "input") {
							if (param.name === payload.name) {
								param.value = payload.value;

								if (this.state.common.patterns[payload.name].test(payload.value)) {
									param.status = "valid";
									param.error = null;
								} else {
									param.status = "invalid";
									param.error = "Неверный формат";
								}
							}
						}
					});

					state.features.validation = true;
					state.features.cancellation = false;

					state.features.params.forEach((param) => {
						if (param.status === "invalid") {
							state.features.validation = false;
						}
					});
				}

				if (payload.section === "logs") {
					if (isEmpty(state.backup.logs)) {
						state.backup.logs = JSON.parse(JSON.stringify(state.logs));
					}

					if (payload.element === "button") {
						state.logs.abort = true;

						state.logs.validation = true;
						state.logs.cancellation = false;
					} else {
						state.logs[payload.name].value = Math.round(payload.value);

						if (payload.val.match(state.logs[payload.name].pattern)) {
							state.logs[payload.name].status = "valid";
							state.logs[payload.name].error = false;
						} else {
							state.logs[payload.name].status = "invalid";
							state.logs[payload.name].error = true;
						}

						if (state.logs.date_logs_start.status === "valid" && state.logs.date_logs_end.status === "valid") {
							state.logs.validation = true;
						} else {
							state.logs.validation = false;
						}

						state.logs.cancellation = false;
					}
				}

				if (payload.section === "records") {
					if (isEmpty(state.backup.records)) {
						state.backup.records = JSON.parse(JSON.stringify(state.records));
					}

					if (payload.element === "dropdown") {
						if (payload.name === "records_list") {
							let systemID = localStorage.getItem("system__system-id");

							state.records.list.value = payload.value;

							state.records.file = {
								name: payload.alias,
								value: payload.value,
							};

							state.records.lines = [];

							axios
								.get(
									`${this.state.common.api}/logs/${systemID}/mqtt/${payload.alias}/upload`,
									this.state.common.headers
								)
								.then((response) => {
									try {
										let newStr = response.data.replace(/[\r\n]\s\s|\r\n$/gi, "");
										let arr = [];
										arr = !newStr ? null : newStr.split(/[\r\n]+/);

										state.records.lines = arr.map((item) => {
											return parse(item);
										});

										state.records.backup = JSON.parse(JSON.stringify(state.records.lines));
										state.records.filter.value = "ALL";
										state.records.search.query = "";
										state.records.search.validation = false;
									} catch (error) {
										console.error(error);
									}
								});
						}

						if (payload.name === "records_filter") {
							state.records.filter.value = payload.value;

							if (!state.records.list.value) return;

							if (state.records.filter.value === "ALL" && state.records.search.query === "") {
								state.records.lines = state.records.backup;
							} else if (state.records.filter.value === "ALL" && state.records.search.query !== "") {
								state.records.lines = state.records.backup.filter((line) =>
									line?.message?.toLowerCase().includes(state.records.search.query.toLowerCase())
								);
							} else {
								state.records.lines = state.records.backup.filter(
									(line) =>
										line?.severity?.toLowerCase().includes(state.records.filter.value.toLowerCase()) &&
										line?.message?.toLowerCase().includes(state.records.search.query.toLowerCase())
								);
							}
						}
					}

					if (payload.element === "input") {
						state.records.search.query = payload.value;

						if (payload.value.length > 1) state.records.search.validation = true;
						else state.records.search.validation = false;

						if (state.records.filter.value === "ALL" && state.records.search.query === "") {
							state.records.lines = state.records.backup;
						}
					}

					if (payload.element === "button") {
						if (payload.name === "records_search_clear") {
							state.records.search.query = "";
							state.records.search.validation = false;

							if (state.records.filter.value === "ALL" && state.records.search.query === "") {
								state.records.lines = state.records.backup;
							} else if (state.records.filter.value === "ALL" && state.records.search.query !== "") {
								state.records.lines = state.records.backup.filter((line) =>
									line?.message?.toLowerCase().includes(state.records.search.query.toLowerCase())
								);
							} else {
								state.records.lines = state.records.backup.filter(
									(line) =>
										line?.severity?.toLowerCase().includes(state.records.filter.value.toLowerCase()) &&
										line?.message?.toLowerCase().includes(state.records.search.query.toLowerCase())
								);
							}
						}

						if (payload.name === "records_search_apply") {
							if (state.records.filter.value === "ALL" && state.records.search.query === "") {
								state.records.lines = state.records.backup;
							} else if (state.records.filter.value === "ALL" && state.records.search.query !== "") {
								state.records.lines = state.records.backup.filter((line) =>
									line?.message?.toLowerCase().includes(state.records.search.query.toLowerCase())
								);
							} else {
								state.records.lines = state.records.backup.filter(
									(line) =>
										line?.severity?.toLowerCase().includes(state.records.filter.value.toLowerCase()) &&
										line?.message?.toLowerCase().includes(state.records.search.query.toLowerCase())
								);
							}
						}

						if (payload.name === "records_clear") {
							state.records.list.value = null;
							state.records.lines = [];
							state.records.file = null;
							state.records.filter.value = "ALL";
							state.records.search.query = "";
						}
					}

					if (payload.element === "keyboard") {
						if (payload.key === "Enter" && state.records.search.validation) {
							if (state.records.filter.value === "ALL" && state.records.search.query === "") {
								state.records.lines = state.records.backup;
							} else if (state.records.filter.value === "ALL" && state.records.search.query !== "") {
								state.records.lines = state.records.backup.filter((line) =>
									line?.message?.toLowerCase().includes(state.records.search.query.toLowerCase())
								);
							} else {
								state.records.lines = state.records.backup.filter(
									(line) =>
										line?.severity?.toLowerCase().includes(state.records.filter.value.toLowerCase()) &&
										line?.message?.toLowerCase().includes(state.records.search.query.toLowerCase())
								);
							}
						}
					}
				}
			}
		},
		handleSystemZone(state, payload) {
			function isEmpty(obj) {
				for (let key in obj) {
					return false;
				}
				return true;
			}

			state.zones.forEach((zone, index) => {
				if (zone.id === payload.id) {
					zone.current = true;

					if (isEmpty(state.backup.zones)) {
						state.backup.zones = JSON.parse(JSON.stringify(state.zones));
					} else if (isEmpty(state.backup.zones[index])) {
						state.backup.zones[index] = JSON.parse(JSON.stringify(state.zones[index]));
					}
				}
			});

			if (payload.event === "change" || payload.event === "input") {
				state.zones.forEach((zone, index) => {
					if (zone.id === payload.id) {
						let ignor = false;

						if (isEmpty(state.backup.zones[index])) {
							state.backup.zones[index] = JSON.parse(JSON.stringify(state.zones[index]));
						}

						if (payload.element === "input") {
							if (payload.name === "name") {
								zone[payload.name].value = payload.value;

								if (payload.value.match(/^[а-яА-ЯёЁa-zA-Z0-9\s-/\\]{2,30}$/))
									zone[payload.name].status = "valid";
								else zone[payload.name].status = "invalid";
							}

							if (payload.name === "address") {
								zone.device.input.value = payload.value;

								if (payload.value.match(/^[0-9]{1,5}$/)) {
									zone.device.input.status = "valid";
									zone.device.button.disabled = false;
								} else {
									zone.device.input.status = "invalid";
									zone.device.button.disabled = true;
								}
							}

							if (payload.name === "period") {
								zone.programs.forEach((program) => {
									if (program.id === payload.regime) {
										zone.regime.periods.forEach((period) => {
											if (period.name === payload.period) {
												program.changed = true;
												period.set = +payload.value;

												if (payload.value.match(this.state.common.patterns[payload.channel])) {
													period.status = "valid";
												} else {
													period.status = "invalid";
												}
											}
										});
									}
								});
							}

							if (payload.name === "t_open" || payload.name === "t_close") {
								zone.hum[payload.name].value = +payload.value;

								if (payload.value.match(this.state.common.patterns[payload.name])) {
									zone.hum[payload.name].status = "valid";
								} else {
									zone.hum[payload.name].status = "invalid";
								}
							}

							if (payload.name === "quan") {
								zone.nozzle.input.value = payload.value;

								if (payload.value.match(/^[0-9]{1,5}$/)) {
									zone.nozzle.input.status = "valid";
									zone.nozzle.button.disabled = false;
								} else {
									zone.nozzle.input.status = "invalid";
									zone.nozzle.button.disabled = true;
								}
							}

							if (payload.name === "min") {
								zone[payload.param][payload.name].value = payload.value;

								if (payload.value.match(this.state.common.patterns[payload.name])) {
									if (
										+payload.value >= zone[payload.param][payload.name].limit &&
										+payload.value < zone[payload.param].max.value
									) {
										zone[payload.param][payload.name].value = +payload.value;
										zone[payload.param][payload.name].status = "valid";
									} else {
										zone[payload.param][payload.name].status = "invalid";
									}
								} else {
									zone[payload.param][payload.name].status = "invalid";
								}
							}

							if (payload.name === "max") {
								zone[payload.param][payload.name].value = payload.value;

								if (payload.value.match(this.state.common.patterns[payload.name])) {
									if (
										+payload.value <= zone[payload.param][payload.name].limit &&
										+payload.value > zone[payload.param].min.value
									) {
										zone[payload.param][payload.name].value = +payload.value;
										zone[payload.param][payload.name].status = "valid";
									} else {
										zone[payload.param][payload.name].status = "invalid";
									}
								} else {
									zone[payload.param][payload.name].status = "invalid";
								}
							}

							if (payload.name === "gys") {
								zone[payload.param][payload.name].value = payload.value;

								if (payload.value.match(this.state.common.patterns[`${payload.name}_${payload.param}`])) {
									if (+payload.value <= zone[payload.param][payload.name].limit) {
										zone[payload.param][payload.name].value = +payload.value;
										zone[payload.param][payload.name].status = "valid";
									} else {
										zone[payload.param][payload.name].status = "invalid";
									}
								} else {
									zone[payload.param][payload.name].status = "invalid";
								}
							}
						}

						if (payload.element === "switch") {
							if (payload.name === "active_srv") {
								if (payload.param !== null) {
									zone[payload.param].active_srv = payload.value;

									if (zone[payload.param].active_srv === false) {
										zone[payload.param].setting = zone[payload.param].default;
										zone[payload.param].status = "default";
									}
								}
							}

							if (payload.name === "regime_active_srv") {
								if (payload.param !== null) {
									zone.programs.forEach((program) => {
										if (program.id === payload.regime) {
											program.changed = true;
											program.active_srv = payload.value;
											zone.regime.program = program;
										}
									});

									zone.regime.active_srv = zone.regime.program.active_srv;
								}
							}
						}

						if (payload.element === "slider") {
							zone[payload.param][payload.name] = payload.value;
						}

						if (payload.element === "dropdown") {
							if (payload.name === "num") {
								if (payload.open) {
									let common = [...Array(31)].map((el, i) => {
										return {
											id: i + 1,
											name: i + 1,
											title: `Зона ${i + 1}`,
										};
									});

									let arr = this.state.system.zones;

									for (let i = 0; i < arr.length; i++) {
										for (let j = 0; j < common.length; j++) {
											if (common[j].id === arr[i].num.val) {
												common.splice(common.indexOf(common[j]), 1);
											}
										}
									}

									zone.num.categories = common;
								} else {
									zone.num.value = payload.value;
									zone.num.val = +payload.alias;
								}
							}

							if (payload.name === "program") {
								ignor = true;

								zone.regime.list.value = payload.value;
								zone.regime.type = payload.alias;

								zone.regime.program = zone.programs.find((program) => program.id == payload.itemID);
								zone.regime.id = zone.regime.program.id;
								zone.regime.active_srv = zone.regime.program.active_srv;
								zone.regime.periods = zone.regime.program.periods;
								zone.regime.days = zone.regime.program.days;
							}

							if (payload.name === "device") {
								zone.device.dropdown.value = payload.value;
								zone.device.dropdown.alias = payload.alias;
								zone.device.dropdown.status = "valid";
							}

							if (payload.name === "nozzle") {
								zone.nozzle.dropdown.value = payload.value;
								zone.nozzle.dropdown.alias = payload.alias;
								zone.nozzle.dropdown.status = "valid";
							}
						}

						if (payload.element === "timepicker") {
							zone.programs.forEach((program) => {
								if (program.id === payload.regime) {
									program.changed = true;

									program.periods.forEach((period) => {
										if (period.name === payload.period) {
											period.start[payload.unit] = payload.value;
										}
									});

									zone.regime.periods = program.periods;
								}
							});
						}

						if (payload.element === "checkbox") {
							state.zones.forEach((zone) => {
								if (zone.id === payload.id) {
									zone.programs.forEach((program) => {
										program.changed = true;

										if (program.id === payload.regime) {
											zone.regime.days.forEach((day) => {
												if (day.num === payload.day) day.activity = payload.value;
											});
										} else {
											program.days.forEach((day) => {
												if (day.num === payload.day) day.activity = !payload.value;
											});
										}
									});
								}
							});
						}

						if (payload.element === "add") {
							if (payload.block === "devs") {
								let obj2 = {
									type: zone.device.dropdown.alias,
									addr: +zone.device.input.value,
									index: 0,
								};

								zone[payload.block].push(obj2);

								let sIndex = 0;
								let gIndex = 0;

								zone[payload.block].forEach((dev) => {
									if (dev.type === "s") dev.index = ++sIndex;
									if (dev.type === "g") dev.index = ++gIndex;
								});

								zone.device.dropdown.value = null;
								zone.device.dropdown.alias = null;
								zone.device.dropdown.status = "default";

								zone.device.input.value = null;
								zone.device.input.status = "default";

								zone.device.button.disabled = true;
							}

							if (payload.block === "nozzles") {
								let obj3 = {
									type: zone.nozzle.dropdown.alias,
									quan: +zone.nozzle.input.value,
								};

								zone[payload.block].push(obj3);

								zone.nozzle.dropdown.value = null;
								zone.nozzle.dropdown.alias = null;
								zone.nozzle.dropdown.status = "default";

								zone.nozzle.input.value = null;
								zone.nozzle.input.status = "default";

								zone.nozzle.button.disabled = true;
							}
						}

						if (payload.element === "delete") {
							zone[payload.modules] = zone[payload.modules].filter((element, index) => index !== payload.index);
						}

						if (payload.param === "hum") {
							if (zone.device.dropdown.status === "valid" && zone.device.input.status === "valid") {
								zone.device.button.disabled = false;
							}

							if (zone.nozzle.dropdown.status === "valid" && zone.nozzle.input.status === "valid") {
								zone.nozzle.button.disabled = false;
							}
						}

						if (!ignor) {
							zone.cancellation = false;
							zone.validation = true;
							if (zone.name.status === "invalid") zone.validation = false;

							if (zone.hum) {
								if (
									zone.hum.status === "invalid" ||
									zone.hum.min.status === "invalid" ||
									zone.hum.max.status === "invalid" ||
									zone.hum.t_open.status === "invalid" ||
									zone.hum.t_close.status === "invalid"
								) {
									zone.validation = false;
								}
							}

							if (zone.temp) {
								if (
									zone.temp.status === "invalid" ||
									zone.temp.min.status === "invalid" ||
									zone.temp.max.status === "invalid"
								) {
									zone.validation = false;
								}
							}

							if (zone.carb) {
								if (
									zone.carb.status === "invalid" ||
									zone.carb.min.status === "invalid" ||
									zone.carb.max.status === "invalid"
								) {
									zone.validation = false;
								}
							}
						}
					}
				});
			}

			if (payload.event === "focus") {
				state.zones.forEach((element) => {
					if (element.id === payload.id) {
						if (payload.param !== null) {
							if (isNaN(+payload.value.slice(0, -1))) element[payload.param][payload.name] = "";
							else element[payload.param][payload.name] = +payload.value.slice(0, -1);

							element[payload.param].unit = "";
						} else {
							element[payload.name] = payload.value;
						}
					}
				});
			}

			if (payload.event === "blur") {
				state.zones.forEach((element) => {
					if (element.id === payload.id) {
						if (payload.param !== null) {
							element[payload.param].unit = "";

							if (element[payload.param].set === "") {
								element[payload.param].set = 0;
								element[payload.param].status = "valid";
							}

							if (payload.param === "hum") element.hum.unit = "%";
							if (payload.param === "temp") element.temp.unit = "°";

							element.cancellation = false;
							element.validation = true;

							if (
								element.name.status === "invalid" ||
								element.hum.status === "invalid" ||
								element.temp.status === "invalid"
							)
								element.validation = false;
						}
					}
				});
			}

			if (payload.event === "click") {
				if (payload.element === "tab") {
					state.zones.forEach((zone) => {
						if (zone.id === payload.id) {
							zone.programs.forEach((program) => {
								if (program.channel === payload.channel && program.type === zone.regime.type) {
									zone.regime.program = program;
									zone.regime.id = program.id;

									zone.regime.channel = program.channel;
									zone.regime.periods = program.periods;
									zone.regime.days = program.days;

									zone.regime.periods.forEach((period) => {
										if (payload.channel === "hum") period.unit = "%";
										if (payload.channel === "temp") period.unit = "°";
										if (payload.channel === "carb") period.unit = "PPM";
									});
								}
							});

							zone.regime.active_srv = zone.regime.program.active_srv;
						}
					});
				}
				if (payload.element === "section") {
					let zone = state.zones.find((zone) => zone.id === payload.id);
					zone.regime.extended = !zone.regime.extended;
				}
			}
		},
		resetSystemsState(state) {
			if (state.zones && state.zones.length) {
				state.zones.forEach((zone, index) => {
					if (state.backup.zones && state.backup.zones.length > 0) {
						if (zone.id === state.backup.zones[index].id) {
							zone.hum = state.backup.zones[index].hum;
							zone.temp = state.backup.zones[index].temp;
							zone.carb = state.backup.zones[index].carb;
							zone.name = state.backup.zones[index].name;
							zone.num = state.backup.zones[index].num;
							zone.device = state.backup.zones[index].device;
							zone.devs = state.backup.zones[index].devs;
							zone.nozzle = state.backup.zones[index].nozzle;
							zone.nozzles = state.backup.zones[index].nozzles;
							zone.programs = state.backup.zones[index].programs;
							zone.regime = state.backup.zones[index].regime;
							zone.status = "default";
							state.backup.zones[index] = {};
						}
					}
					zone.current = false;
					zone.cancellation = true;
					zone.validation = false;
					zone.extended = false;
					zone.regime.extended = false;
				});
			}

			if (state.entrances) {
				state.entrances.forEach((entrance) => {
					entrance.current = false;
					entrance.cancellation = true;
					entrance.validation = false;
					entrance.extended = false;
				});
			}

			state.deleting = true;
		},
		cancelSystemChanges(state, payload) {
			if (payload.param === "about") {
				state.about.cancellation = true;
				state.about.validation = false;

				state.about.name.value = state.backup.about.name.value;
				state.about.name.status = "default";
				state.about.name.error = false;

				state.about.address.value = state.backup.about.address.value;
				state.about.address.status = "default";
				state.about.address.error = false;

				state.about.date_start.value = state.backup.about.date_start.value;
				state.about.date_start.status = "default";
				state.about.date_start.error = false;

				state.about.date_install.value = state.backup.about.date_install.value;
				state.about.date_install.status = "default";
				state.about.date_install.error = false;

				state.about.date_warranty.value = state.backup.about.date_warranty.value;
				state.about.date_warranty.status = "default";
				state.about.date_warranty.error = false;

				state.about.date_maintenance_last.value = state.backup.about.date_maintenance_last.value;
				state.about.date_maintenance_last.status = "default";
				state.about.date_maintenance_last.error = false;

				state.about.date_maintenance_plan.value = state.backup.about.date_maintenance_plan.value;
				state.about.date_maintenance_plan.status = "default";
				state.about.date_maintenance_plan.error = false;

				state.about.comment.value = state.backup.about.comment.value;
				state.about.comment.status = "default";
				state.about.comment.error = false;

				state.about.vacation = state.backup.about.vacation;

				state.about.owner.forEach((param, index) => {
					param.value = state.backup.about.owner[index].value;
					param.status = "default";
					param.error = false;
				});

				state.backup.about = {};
			}

			if (payload.param === "zone") {
				state.zones.forEach((element, index) => {
					if (element.id == payload.id) {
						element.hum = state.backup.zones[index].hum;
						element.temp = state.backup.zones[index].temp;
						element.carb = state.backup.zones[index].carb;
						element.name = state.backup.zones[index].name;
						element.device = state.backup.zones[index].device;
						element.devs = state.backup.zones[index].devs;
						element.nozzle = state.backup.zones[index].nozzle;
						element.nozzles = state.backup.zones[index].nozzles;
						element.programs = state.backup.zones[index].programs;
						element.regime = state.backup.zones[index].regime;
						element.status = "default";
						element.cancellation = true;
						element.validation = false;
						element.extended = false;

						state.backup.zones[index] = {};
					}
				});
			}

			if (payload.param === "config") {
				state.config.cancellation = true;
				state.config.validation = false;
				state.config.permission_for_work = state.backup.config.permission_for_work;

				state.config.params.forEach((param, index) => {
					if (param.alias) param.alias = state.backup.config.params[index].alias;
					param.value = state.backup.config.params[index].value;
					param.status = "default";
					param.error = false;
				});

				state.backup.config = {};
			}

			if (payload.param === "control") {
				state.control.manual_control = state.backup.control.manual_control;
				state.control.valve_drain = state.backup.control.valve_drain;
				state.control.valve_in = state.backup.control.valve_in;
				state.control.pump = state.backup.control.pump;
				state.control.driver = state.backup.control.driver;
				state.control.zones = state.backup.control.zones;
				state.control.cancellation = true;
				state.control.validation = false;

				state.backup.control = {};
			}

			if (payload.param === "modes") {
				state.modes.list = state.backup.modes.list;
				state.modes.cancellation = true;
				state.modes.validation = false;

				state.backup.modes = {};
			}

			if (payload.param === "features") {
				state.features.cancellation = true;
				state.features.validation = false;

				state.features.params.forEach((param, index) => {
					if (param.alias) param.alias = state.backup.features.params[index].alias;
					param.value = state.backup.features.params[index].value;
					param.status = "default";
					param.error = false;
				});

				state.backup.features = {};
			}

			if (payload.param === "firmware") {
				state.firmware.version = state.backup.firmware.version;
				state.firmware.file = null;
				state.firmware.processing = false;
				state.firmware.list.value = null;

				state.firmware.cancellation = true;
				state.firmware.validation = false;

				state.backup.firmware = {};
			}

			if (payload.param === "logs") {
				state.logs.date_logs_start.value = state.backup.logs.date_logs_start.value;
				state.logs.date_logs_start.status = "default";
				state.logs.date_logs_start.error = false;

				state.logs.date_logs_end.value = state.backup.logs.date_logs_end.value;
				state.logs.date_logs_end.status = "default";
				state.logs.date_logs_end.error = false;

				state.logs.abort = false;
				state.logs.cancellation = true;
				state.logs.validation = false;

				state.backup.logs = {};
			}
		},
		clearSystemSection(state, payload) {
			state[payload].cancellation = true;
			state[payload].validation = false;
			state.backup[payload] = {};
		},
		clearSystemState(state) {
			state.navbar = {};
			state.about = {
				extended: localStorage.getItem("system__about-extended") === "true" ? true : false || false,
				cancellation: true,
				validation: false,
			};
			state.zones = [];
			state.tasks = [];
			state.config = {
				extended: false,
				cancellation: true,
				validation: false,
			};
			state.state = {
				network: {
					type: null,
					status: null,
					indicator: null,
					timer: null,
					countdown: null,
				},
				extended: localStorage.getItem("system__state-extended") === "true" ? true : false || false,
			};
			state.control = {
				extended: localStorage.getItem("system__control-extended") === "true" ? true : false || false,
				cancellation: true,
				validation: false,
			};
			state.modes = {
				extended: localStorage.getItem("system__modes-extended") === "true" ? true : false || false,
				cancellation: true,
				validation: false,
			};
			state.features = {
				extended: localStorage.getItem("system__features-extended") === "true" ? true : false || false,
				cancellation: true,
				validation: false,
			};
			state.firmware = {
				extended: localStorage.getItem("system__firmware-extended") === "true" ? true : false || false,
				cancellation: true,
				validation: false,
			};
			state.tasks = [];
			state.deleting = false;
			state.backup = {};
		},
		updateSystemAboutSocket(state, payload) {
			if (payload.mileage) state.about.mileage = +payload.mileage * 10000;
			if (payload.pump_hours) state.about.pump_hours = payload.pump_hours;

			if (payload.state) {
				if (payload.state === 0) {
					state.about.state = {
						value: "disabled",
						text: "выключена",
					};

					state.config.state = {
						value: "disabled",
						text: "выключена",
					};
				}

				if (payload.state === 2) {
					state.about.state = {
						value: "error",
						text: "ошибка",
					};

					state.config.state = {
						value: "error",
						text: "ошибка",
					};
				}

				if (payload.state === 3) {
					state.about.state = {
						value: "active",
						text: "работает",
					};

					state.config.state = {
						value: "active",
						text: "работает",
					};
				}

				if (payload.state === 4) {
					state.about.state = {
						value: "inactive",
						text: "не работает",
					};

					state.config.state = {
						value: "inactive",
						text: "не работает",
					};
				}
			}
		},
		updateSystemZonesSocket(state, payload) {
			if (state.zones !== undefined && state.zones.length > 0) {
				state.zones.forEach((zone) => {
					if (zone.id === payload.id && zone.cancellation) {
						zone.motion = payload.motion;

						if (zone.hum) {
							zone.hum.cur = payload.hum.cur;
							zone.hum.set = payload.hum.set;
							zone.hum.activity = payload.hum.activity;
						}

						if (zone.temp) {
							zone.temp.cur = payload.temp.cur;
							zone.temp.set = payload.temp.set;
							zone.temp.activity = payload.temp.activity;
						}

						if (zone.carb) {
							zone.carb.cur = payload.carb.cur;
							zone.carb.set = payload.carb.set;
							zone.carb.activity = payload.carb.activity;
						}

						if (payload.programs) {
							zone.programs = payload.programs;

							zone.programs.forEach((program) => {
								program.periods.forEach((period) => {
									let start = period.start.split(":", 2);

									period.start = {
										hour: +start[0],
										minute: +start[1],
									};
								});

								let days = [
									{ num: 1, name: "ПН", activity: false },
									{ num: 2, name: "ВТ", activity: false },
									{ num: 3, name: "СР", activity: false },
									{ num: 4, name: "ЧТ", activity: false },
									{ num: 5, name: "ПТ", activity: false },
									{ num: 6, name: "СБ", activity: false },
									{ num: 7, name: "ВС", activity: false },
								];

								program.days = days.map((elem) => {
									elem.activity = false;
									let programDay = program.days.find((d) => elem.num == d);

									if (programDay) elem.activity = true;

									return elem;
								});

								if (program.id === zone.regime.id) {
									zone.regime.programs = payload.programs;
									zone.regime.program = program;
									zone.regime.active_srv = program.active_srv;
									zone.regime.periods = program.periods;
									zone.regime.days = program.days;
								}
							});
						}
					}
				});
			}
		},
		updateSystemModesSocket(state, payload) {
			state.modes.list.forEach((mode) => {
				if (mode.id === payload.modes[0].id) {
					mode = payload.modes[0];

					let days = [
						{ num: 1, name: "ПН", activity: false },
						{ num: 2, name: "ВТ", activity: false },
						{ num: 3, name: "СР", activity: false },
						{ num: 4, name: "ЧТ", activity: false },
						{ num: 5, name: "ПТ", activity: false },
						{ num: 6, name: "СБ", activity: false },
						{ num: 7, name: "ВС", activity: false },
					];

					if (!mode.days) mode.days = [1, 2];

					mode.days = days.map((elem) => {
						elem.activity = false;
						let modeDay = mode.days.find((d) => elem.num == d);

						if (modeDay) elem.activity = true;

						return elem;
					});

					if (!mode.start) mode.start = ["00:00"];

					mode.start = mode.start.map((item, ind) => {
						let start = item.split(":", 2);
						let obj = {
							id: ind,
							hour: +start[0],
							minute: +start[1],
						};

						return obj;
					});

					mode.duration = {
						name: "duration",
						title: "Длительность",
						value: mode.duration,
						error: null,
						status: "default",
					};

					if (mode.t_deadzone) {
						mode.t_deadzone = {
							name: "t_deadzone",
							title: "Длительность",
							value: mode.t_deadzone,
							error: null,
							status: "default",
						};
					}

					if (mode.zones && mode.zones.length) {
						mode.zones.forEach((zone) => {
							if (zone.t_drain) {
								zone.t_drain = {
									name: "t_drain",
									title: "Длительность",
									value: zone.t_drain,
									error: null,
									status: "default",
								};
							}
						});
					}
				}
			});
		},
	},
	actions: {
		fetchSystem(context, payload) {
			if (payload === "default") {
				context.state.navbar.back = "Список систем";

				let payload = {
					visible: true,
					title: "Уведомление!",
					text: `
              В настоящий момент не выбрана ни одна система. Перейдите к списку
              систем и выберите необходимую.
              `,
				};

				context.commit("createNotification", payload);
			} else {
				context.commit("showPreloader");
				context.commit("destroyNotification");
				context.commit("clearSystemState");

				localStorage.setItem(`system__socket-rate`, false);

				axios
					.get(`${this.state.common.api}/getSystem/${payload}`, this.state.common.headers)
					.then((response) => {
						context.state.state.network.type = "request";

						context.commit("updateSystemNavbar", response.data);
						context.commit("updateSystemAbout", response.data);
						context.commit("updateSystemChildren", response.data);
						context.commit("updateSystemZones", response.data);

						context.commit("updateSystemEntrances", response.data);

						context.commit("updateSystemBoard", response.data);
						context.commit("updateSystemConfig", response.data);
						context.commit("updateSystemState", response.data);
						context.commit("updateSystemControl", response.data);
						context.commit("updateSystemModes", response.data);
						context.commit("updateSystemFeatures", response.data);
						context.commit("updateSystemFirmware", response.data);
						context.commit("updateSystemLogs", response.data);

						context.commit("hidePreloader");
					})
					.catch((error) => context.commit("handleError", error));

				axios
					.get(`${this.state.common.api}/logs/${payload}/mqtt/`, this.state.common.headers)
					.then((response) => {
						context.commit("updateSystemRecords", response.data);
					})
					.catch((error) => {
						console.log(error);

						context.commit("updateSystemRecords", null);
					});
			}
		},
		fetchSystemTasks(context) {
			context.commit("showPreloader");

			axios
				.get(
					`${this.state.common.api}/tasks?filters[executor][id][$eq]=2&pagination[start]=${this.state.common.pagination.start}&pagination[limit]=${this.state.common.pagination.limit}`,
					this.state.common.headers
				)
				.then((response) => {
					context.commit("updateSystemTasks", response.data);
					context.commit("hidePreloader");
				})
				.catch((error) => context.commit("handleError", error));
		},
		changeSystemActiveStatus(context, payload) {
			context.commit("showPreloader");

			let system = {
				data: {
					active_srv: payload.active,
					active_user: context.state.about.active_user,
					errors: context.state.about.errors,
					sender: "engineer",
				},
			};

			axios
				.put(`${this.state.common.api}/updateSystem/${payload.system}`, system, this.state.common.headers)
				.then((response) => {
					context.commit("updateSystemAbout", response.data);
					context.commit("updateSystemZones", response.data);
					context.commit("updateSystemConfig", response.data);
					context.commit("hidePreloader");
				})
				.catch((error) => context.commit("handleError", error));
		},
		changeSystemOwner(context, payload) {
			context.commit("showPreloader");

			if (payload.owner === "new") {
				let acronym = "";

				if (payload.first_name !== null && payload.last_name !== null) {
					acronym = `${payload.first_name.substr(0, 1)}${payload.last_name.substr(0, 1)}`;
				}

				axios
					.post(
						`${this.state.common.api}/auth/local/register`,
						{
							username: payload.email.substring(0, payload.email.indexOf("@")),
							email: payload.email,
							password: payload.password,
							first_name: payload.first_name,
							last_name: payload.last_name,
							middle_name: payload.middle_name,
							phone: payload.phone,
							acronym: acronym,
							first_entry: true,
						},
						this.state.common.headers
					)
					.then((user) => {
						axios
							.post(
								`${this.state.common.api}/users/sendMailToNewUser`,
								{
									user_id: user.data.user.id,
									password: payload.password,
								},
								this.state.common.headers
							)
							.then(() => {
								let system = {
									data: {
										from_user: user.data.user.id,
									},
								};

								axios
									.put(
										`${this.state.common.api}/updateSystem/${context.state.about.id}`,
										system,
										this.state.common.headers
									)
									.then((response) => {
										context.commit("updateSystemAbout", response.data);
										context.commit("hidePreloader");
									});
							});
					})
					.catch((error) => context.commit("handleError", error));
			}

			if (payload.owner === "exist") {
				axios
					.get(`${this.state.common.api}/users/GetUserIdFromEmail/${payload.email}`, this.state.common.headers)
					.then((user) => {
						let system = {
							data: {
								from_user: user.data.data.id,
							},
						};

						axios
							.put(
								`${this.state.common.api}/updateSystem/${context.state.about.id}`,
								system,
								this.state.common.headers
							)
							.then((response) => {
								context.commit("updateSystemAbout", response.data);
								context.commit("hidePreloader");
							});
					})
					.catch((error) => context.commit("handleError", error));
			}
		},
		addSystemDocument(context, payload) {
			context.commit("showPreloader");

			let formData = new FormData();
			formData.append("files", payload.file);

			axios
				.post(`${this.state.common.api}/upload`, formData, this.state.common.headers)
				.then((file) => {
					axios
						.post(
							`${this.state.common.api}/documents`,
							{
								data: {
									name: payload.name,
									file: file.data[0].id,
									from_system: context.state.about.id,
								},
							},
							this.state.common.headers
						)
						.then(() => {
							axios
								.get(`${this.state.common.api}/getSystem/${context.state.about.id}`, this.state.common.headers)
								.then((response) => {
									context.commit("updateSystemAbout", response.data);
									context.commit("updateSystemZones", response.data);
									context.commit("hidePreloader");
								});
						});
				})
				.catch((error) => context.commit("handleError", error));
		},
		downloadSystemDocument(context, payload) {
			context.commit("showPreloader");

			window.location.href = `${this.state.common.url}${payload.file.url}`;

			context.commit("hidePreloader");
		},
		downloadSystemLog(context, payload) {
			context.commit("showPreloader");

			let systemID = localStorage.getItem("system__system-id");

			window.location.href = `${this.state.common.api}/logs/${systemID}/mqtt/${payload.name}/download`;

			context.commit("hidePreloader");
		},
		deleteSystemDocument(context, payload) {
			context.commit("showPreloader");

			axios
				.delete(`${this.state.common.api}/upload/files/${payload.file}`, this.state.common.headers)
				.then(() => {
					axios.delete(`${this.state.common.api}/documents/${payload.id}`, this.state.common.headers).then(() => {
						axios
							.get(`${this.state.common.api}/getSystem/${context.state.about.id}`, this.state.common.headers)
							.then((response) => {
								context.commit("updateSystemAbout", response.data);
								context.commit("updateSystemZones", response.data);
								context.commit("hidePreloader");
							});
					});
				})
				.catch((error) => context.commit("handleError", error));
		},
		resetSystemErrors(context, payload) {
			let system = {
				data: {
					errors: context.state.about.errors
						.filter((item) => item.code === payload.error)
						.map((item) => item.code),
				},
			};

			axios
				.put(`${this.state.common.api}/resetErrors/${context.state.about.id}`, system, this.state.common.headers)
				.then(() => {
					context.commit("hidePreloader");
				})
				.catch((error) => context.commit("handleError", error));
		},
		sendSystemChanges(context, payload) {
			context.commit("showPreloader");

			if (payload === "about") {
				let system = {
					data: {
						name: context.state.about.name.value,
						address: context.state.about.address.value,
						date_start: context.state.about.date_start.value,
						date_install: context.state.about.date_install.value,
						date_warranty: context.state.about.date_warranty.value,
						date_maintenance_last: context.state.about.date_maintenance_last.value,
						date_maintenance_plan: context.state.about.date_maintenance_plan.value,
						comment: context.state.about.comment.value,
					},
				};

				axios
					.put(
						`${this.state.common.api}/updateSystem/${context.state.about.id}`,
						system,
						this.state.common.headers
					)
					.then((response) => {
						if (context.state.about.vacation.changed) {
							let mode = {
								data: {
									active_srv: context.state.about.vacation.active_srv,
								},
							};

							axios
								.put(
									`${this.state.common.api}/modes/${context.state.about.vacation.id}`,
									mode,
									this.state.common.headers
								)
								.then(() => {
									context.commit("clearSystemSection", payload);
									context.commit("hidePreloader");
								})
								.catch((error) => context.commit("handleError", error));
						} else {
							context.commit("updateSystemAbout", response.data);
							context.commit("updateSystemZones", response.data);
							context.commit("clearSystemSection", payload);
							context.commit("hidePreloader");
						}
					})
					.catch((error) => context.commit("handleError", error));
			}

			if (payload === "config") {
				let data = {};
				let prepare = {
					permission_for_work: context.state.config.permission_for_work,
				};

				context.state.config.params.forEach((param) => {
					if (param.name === "tz") prepare[param.name] = param.alias;
					else prepare[param.name] = param.value;
				});

				data = {
					data: prepare,
				};

				axios
					.put(`${this.state.common.api}/updateSystem/${context.state.about.id}`, data, this.state.common.headers)
					.then(() => {
						axios
							.get(`${this.state.common.api}/getSystem/${context.state.about.id}`, this.state.common.headers)
							.then((response) => {
								context.commit("updateSystemAbout", response.data);
								context.commit("updateSystemZones", response.data);
								context.commit("updateSystemConfig", response.data);
								context.commit("updateSystemState", response.data);
								context.commit("updateSystemFeatures", response.data);
								context.commit("hidePreloader");

								context.state.config.cancellation = true;
								context.state.config.validation = false;
								context.state.backup.config = {};
							});
					})
					.catch((error) => context.commit("handleError", error));
			}

			if (payload === "control") {
				let zones = JSON.parse(JSON.stringify(context.state.control.zones));

				for (let i = 0; i < zones.length; i++) {
					zones[i] = {
						id: zones[i].id,
						num: zones[i].num,
						hum: !zones[i].hum ? null : { id: zones[i].hum.id, activity: zones[i].hum.activity },
						temp: !zones[i].temp ? null : { id: zones[i].temp.id, activity: zones[i].temp.activity },
						carb: !zones[i].carb ? null : { id: zones[i].carb.id, activity: zones[i].carb.activity },
					};
				}

				let common = {
					data: {
						manual_control: context.state.control.manual_control,
						pump: context.state.control.pump,
						driver: context.state.control.driver,
						valve_drain: context.state.control.valve_drain,
						valve_in: context.state.control.valve_in,
						zones: zones,
					},
				};

				axios
					.put(
						`${this.state.common.api}/systems/${context.state.about.id}/manualControl`,
						common,
						this.state.common.headers
					)
					.then(() => {
						context.commit("hidePreloader");
						context.state.control.cancellation = true;
						context.state.control.validation = false;
						context.state.backup.control = {};
					})
					.catch((error) => context.commit("handleError", error));
			}

			if (payload === "modes") {
				let modes = context.state.modes.list.filter((mode) => mode.changed);

				if (modes.length) {
					let endpoints = [];

					for (let i = 0; i < modes.length; i++) {
						let modeDays = modes[i].days.filter((day) => day.activity === true).map((day) => (day = day.num));
						let commissioning = null;

						if (modes[i].start && modes[i].start.length) {
							modes[i].start = modes[i].start.map((item) => {
								let modeStart = `${item.hour < 10 ? "0" + item.hour : item.hour}:${
									item.minute < 10 ? "0" + item.minute : item.minute
								}`;

								return modeStart;
							});
						}

						if (modes[i].commissioning) {
							commissioning = modes[i].commissioning;
						}

						let obj = {
							url: `${this.state.common.api}/modes/${modes[i].id}`,
							payload: {
								data: {
									active_srv: modes[i].active_srv,
									days: modeDays,
									start: modes[i].start,
								},
							},
						};

						if (modes[i].zones && modes[i].zones.length) {
							modes[i].zones.forEach((zone) => {
								if (zone.t_drain) zone.t_drain = +zone.t_drain.value;
							});

							obj.payload.data.zones = modes[i].zones;
						}

						if (modes[i].duration && modes[i].duration.value) {
							obj.payload.data.duration = modes[i].duration.value;
						}

						if (commissioning !== null) {
							obj.payload.data.commissioning = commissioning;
						}

						endpoints[i] = obj;
					}

					axios
						.all(
							endpoints.map((endpoint) => axios.put(endpoint.url, endpoint.payload, this.state.common.headers))
						)
						.then(() => {
							axios
								.get(`${this.state.common.api}/getSystem/${context.state.about.id}`, this.state.common.headers)
								.then((response) => {
									context.commit("updateSystemModes", response.data);
									context.commit("clearSystemSection", payload);
									context.commit("hidePreloader");
								});
						})
						.catch((error) => context.commit("handleError", error));
				}
			}

			if (payload === "features") {
				let data = {};
				let features = {};
				let id = localStorage.getItem("system__board-id");

				context.state.features.params.forEach((param) => {
					if (param.alias) features[param.name] = param.alias;
					else features[param.name] = +param.value;
				});

				data = {
					data: features,
				};

				axios
					.put(`${this.state.common.api}/boards/${id}`, data, this.state.common.headers)
					.then(() => {
						axios
							.get(`${this.state.common.api}/getSystem/${context.state.about.id}`, this.state.common.headers)
							.then((response) => {
								context.commit("updateSystemAbout", response.data);
								context.commit("updateSystemZones", response.data);
								context.commit("updateSystemFeatures", response.data);
								context.commit("clearSystemSection", payload);
								context.commit("hidePreloader");
							});
					})
					.catch((error) => context.commit("handleError", error));
			}

			if (payload === "firmware_upload") {
				let formData = new FormData();
				formData.append("files", context.state.firmware.file);

				axios
					.post(`${this.state.common.api}/upload`, formData, this.state.common.headers)
					.then((response) => {
						axios
							.post(
								`${this.state.common.api}/firmwares`,
								{
									data: {
										file: response.data[0].id,
										boardID: +localStorage.getItem("system__board-id"),
									},
								},
								this.state.common.headers
							)
							.then(() => {
								axios.get(`${this.state.common.api}/firmwares`, this.state.common.headers).then((response) => {
									context.commit("updateSystemFirmware", response.data);
									context.commit("hidePreloader");
								});
							});
					})
					.catch((error) => context.commit("handleError", error));
			}

			if (payload === "firmware_update") {
				context.commit("hidePreloader");
				context.state.firmware.processing = true;
				context.state.firmware.warning = `
					Прошивка проверяется. Дождитесь окончания процедуры проверки и не перезагружайте страницу!
				`;

				let data = {
					data: {
						boardID: +localStorage.getItem("system__board-id"),
						firmwareID: context.state.firmware.list.id,
					},
				};

				axios
					.put(`${this.state.common.api}/updateFirmware`, data, this.state.common.headers)
					.then(() => {
						context.commit("clearSystemSection", "firmware");
					})
					.catch((error) => context.commit("handleError", error));
			}

			if (payload === "logs") {
				if (context.state.logs.abort) {
					let data = {
						data: {
							board_id: +localStorage.getItem("system__board-id"),
							transfer: "abort",
						},
					};

					axios
						.post(`${this.state.common.api}/getBoardLogs`, data, this.state.common.headers)
						.then((response) => {
							if (response.data.data && response.data.data.message) {
								context.state.logs.processing = true;
								context.state.logs.warning = response.data.data.message;

								context.commit("clearSystemSection", "logs");
							}

							context.commit("hidePreloader");
						})
						.catch((error) => context.commit("handleError", error));
				} else {
					let data = {
						data: {
							board_id: +localStorage.getItem("system__board-id"),
							interval_start: new Date(context.state.logs.date_logs_start.value * 1000).toISOString(),
							interval_stop: new Date(context.state.logs.date_logs_end.value * 1000).toISOString(),
						},
					};

					context.state.logs.date_logs_start.value = null;
					context.state.logs.date_logs_start.status = "default";
					context.state.logs.date_logs_start.error = false;

					context.state.logs.date_logs_end.value = null;
					context.state.logs.date_logs_end.status = "default";
					context.state.logs.date_logs_end.error = false;

					axios
						.post(`${this.state.common.api}/getBoardLogs`, data, this.state.common.headers)
						.then((response) => {
							if (response.data.data && response.data.data.message) {
								context.state.logs.processing = true;
								context.state.logs.warning = response.data.data.message;

								context.commit("clearSystemSection", "logs");
							}

							context.commit("hidePreloader");
						})
						.catch((error) => context.commit("handleError", error));
				}
			}
		},
		addSystemZone(context, payload) {
			context.commit("showPreloader");

			let zone = {
				name: payload.name,
				num: payload.num,
				devs: payload.devs,
				nozzles: payload.nozzles,
				from_system: payload.from_system,
			};

			for (const key in payload) {
				if ((key === "hum" || key === "temp" || key === "carb") && payload[key].status === true) {
					zone[key] = {
						set: payload[key].set,
					};
				}
			}

			axios
				.post(
					`${this.state.common.api}/zones`,
					{
						data: zone,
					},
					this.state.common.headers
				)
				.then(() => {
					axios
						.get(
							`${this.state.common.api}/zones/?populate=devs,nozzles,hum,temp&filters[from_system][id][$eq]=${payload.from_system}`,
							this.state.common.headers
						)
						.then(() => {
							axios
								.get(`${this.state.common.api}/getSystem/${context.state.about.id}`, this.state.common.headers)
								.then((response) => {
									context.commit("updateSystemZones", response.data);
									context.commit("updateSystemState", response.data);
									context.commit("updateSystemControl", response.data);
									context.commit("hidePreloader");
								});
						});
				})
				.catch((error) => context.commit("handleError", error));
		},
		changeSystemZone(context, payload) {
			context.commit("showPreloader");

			let zone = {
				name: null,
				num: null,
				event: null,
				devs: null,
				nozzles: null,
				carb: {
					id: null,
					activity: null,
					active_srv: null,
					set: null,
					cur: null,
					min: null,
					max: null,
					gys: null,
				},
				hum: {
					id: null,
					activity: null,
					active_srv: null,
					set: null,
					cur: null,
					min: null,
					max: null,
					t_open: null,
					t_close: null,
					gys: null,
				},
				temp: {
					id: null,
					activity: null,
					active_srv: null,
					set: null,
					cur: null,
					min: null,
					max: null,
					gys: null,
				},
				programs: [],
				initiator: "web",
			};

			context.state.zones.forEach((elem) => {
				if (elem.id === payload.zone) {
					zone.name = elem.name.value;
					zone.num = elem.num.val;
					zone.event = "service_config";
					zone.devs = elem.devs;
					zone.nozzles = elem.nozzles;
					zone.programs = elem.programs;

					if (elem.carb !== null) {
						zone.carb.id = elem.carb.id;
						zone.carb.activity = elem.carb.activity;
						zone.carb.active_srv = elem.carb.active_srv;
						zone.carb.set = elem.carb.set;
						zone.carb.cur = elem.carb.cur;
						zone.carb.min = elem.carb.min.value;
						zone.carb.max = elem.carb.max.value;
						zone.carb.gys = elem.carb.gys.value;
					} else zone.carb = null;

					if (elem.hum !== null) {
						zone.hum.id = elem.hum.id;
						zone.hum.activity = elem.hum.activity;
						zone.hum.active_srv = elem.hum.active_srv;
						zone.hum.set = elem.hum.set;
						zone.hum.cur = elem.hum.cur;
						zone.hum.min = elem.hum.min.value;
						zone.hum.max = elem.hum.max.value;
						zone.hum.t_open = elem.hum.t_open.value;
						zone.hum.t_close = elem.hum.t_close.value;
						zone.hum.gys = elem.hum.gys.value;
					} else zone.hum = null;

					if (elem.temp !== null) {
						zone.temp.id = elem.temp.id;
						zone.temp.activity = elem.temp.activity;
						zone.temp.active_srv = elem.temp.active_srv;
						zone.temp.set = elem.temp.set;
						zone.temp.cur = elem.temp.cur;
						zone.temp.min = elem.temp.min.value;
						zone.temp.max = elem.temp.max.value;
						zone.temp.gys = elem.temp.gys.value;
					} else zone.temp = null;
				}
			});

			axios
				.put(
					`${this.state.common.api}/zones/${payload.zone}`,
					{
						data: zone,
					},
					this.state.common.headers
				)
				.then(() => {
					axios
						.get(
							`${this.state.common.api}/zones/?populate=devs,nozzles,hum,temp&filters[from_system][id][$eq]=${payload.system}`,
							this.state.common.headers
						)
						.then(() => {
							let programs = zone.programs.filter((program) => program.changed);

							if (programs.length) {
								programs.forEach((program) => {
									program.periods = program.periods.map((period) => {
										let obj = {
											id: period.id,
											name: period.name,
											set: period.set,
											start: `${period.start.hour < 10 ? "0" + period.start.hour : period.start.hour}:${
												period.start.minute < 10 ? "0" + period.start.minute : period.start.minute
											}`,
											title: period.title,
										};

										return obj;
									});

									program.days = program.days
										.filter((day) => day.activity === true)
										.map((day) => (day = day.num));

									let prog = {
										data: {
											active_srv: program.active_srv,
											hum: program.hum,
											carb: program.carb,
											temp: program.temp,
											periods: program.periods,
											days: program.days,
										},
									};

									axios
										.put(`${this.state.common.api}/programs/${program.id}`, prog, this.state.common.headers)
										.then(() => {
											axios
												.get(
													`${this.state.common.api}/getSystem/${context.state.about.id}`,
													this.state.common.headers
												)
												.then((response) => {
													context.commit("updateSystemZones", response.data);
													context.commit("updateSystemState", response.data);
													context.commit("updateSystemControl", response.data);
													context.commit("hidePreloader");
												});
										})
										.catch((error) => context.commit("handleError", error));
								});
							} else {
								axios
									.get(
										`${this.state.common.api}/getSystem/${context.state.about.id}`,
										this.state.common.headers
									)
									.then((response) => {
										context.commit("updateSystemZones", response.data);
										context.commit("updateSystemState", response.data);
										context.commit("updateSystemControl", response.data);
										context.commit("hidePreloader");
									});
							}

							context.state.zones.forEach((elem, index) => {
								if (elem.id === payload.zone) {
									context.state.backup.zones[index] = {};
								}
							});
						});
				})
				.catch((error) => context.commit("handleError", error));
		},
		deleteSystemZone(context, payload) {
			context.commit("showPreloader");

			axios
				.delete(`${this.state.common.api}/zones/${payload.zone}`, this.state.common.headers)
				.then(() => {
					axios
						.get(
							`${this.state.common.api}/zones/?populate=devs,nozzles,hum,temp&filters[from_system][id][$eq]=${payload.system}`,
							this.state.common.headers
						)
						.then(() => {
							axios
								.get(`${this.state.common.api}/getSystem/${context.state.about.id}`, this.state.common.headers)
								.then((response) => {
									context.commit("updateSystemZones", response.data);
									context.commit("updateSystemState", response.data);
									context.commit("updateSystemControl", response.data);
									context.commit("hidePreloader");
								});
						});
				})
				.catch((error) => context.commit("handleError", error));
		},
		confirmSystemLogs(context) {
			context.commit("showPreloader");

			let id = localStorage.getItem("system__board-id");

			axios
				.get(`${this.state.common.api}/getBoardLogs/${id}`, this.state.common.headers)
				.then((response) => {
					context.commit("updateSystemLogs", response.data.data);
					context.commit("hidePreloader");
				})
				.catch((error) => context.commit("handleError", error));
		},
		addSystemEntrance(context, payload) {
			context.commit("showPreloader");

			let entrance = {
				name: payload.name,
				floors: payload.floors,
				pipelines: payload.pipelines,
				filtres: payload.filtres,
				pumps: payload.pumps,
				collectors: payload.collectors,
				controllers: payload.controllers,
				from_system: payload.from_system,
			};

			axios
				.post(
					`${this.state.common.api}/systems-pro/entrances`,
					{
						data: entrance,
					},
					this.state.common.headers
				)
				.then(() => {
					axios
						.get(`${this.state.common.api}/getSystem/${payload.from_system}`, this.state.common.headers)
						.then((response) => {
							context.commit("updateSystemEntrances", response.data);

							context.commit("hidePreloader");
						})
						.catch((error) => context.commit("handleError", error));
				})
				.catch((error) => context.commit("handleError", error));
		},
		deleteSystemEntrance(context, payload) {
			context.commit("showPreloader");

			axios
				.delete(`${this.state.common.api}/systems-pro/entrances/${payload.entrance}`, this.state.common.headers)
				.then(() => {
					axios
						.get(`${this.state.common.api}/getSystem/${payload.system}`, this.state.common.headers)
						.then((response) => {
							context.commit("updateSystemEntrances", response.data);

							context.commit("hidePreloader");
						})
						.catch((error) => context.commit("handleError", error));
				})
				.catch((error) => context.commit("handleError", error));
		},
	},
};
