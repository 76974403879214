<template>
	<div :class="zone.current ? 'zone zone_current' : 'zone'" :data-id="zone.id" @click="handleZone">
		<div class="zone__body">
			<div class="zone__motion" v-if="!zone.motion">
				<font-awesome-icon icon="fa-solid fa-person-walking-arrow-right" />
			</div>
			<div class="zone__name">
				<input
					:class="{
						'zone__name-input': true,
						'zone__name-input_valid': zone.name.status === 'valid',
						'zone__name-input_invalid': zone.name.status === 'invalid',
					}"
					type="text"
					:name="'name'"
					:status="zone.name.status"
					:value="zone.name.value"
					@input="handleZone($event, 'input')"
					@change="handleZone($event, 'input')"
				/>
			</div>
			<Dropdown
				:type="zone.num.type"
				:name="zone.num.name"
				:items="zone.num.categories"
				:value="zone.num.value"
				:status="zone.num.status"
				v-if="zone.level === 'srv'"
				@click="handleZone($event, 'dropdown')"
			/>
			<section class="zone__prog">
				<div class="zone__prog-header" @click="handleZone($event, 'section')">
					<div class="zone__prog-title">Программы</div>
					<div class="zone__prog-control">
						<button class="zone__prog-button">
							<span
								:class="[
									zone.regime.extended
										? 'zone__prog-button-icon zone__prog-button-icon_rotate'
										: 'zone__prog-button-icon',
								]"
							></span>
						</button>
					</div>
				</div>
				<div class="zone__prog-body" v-show="zone.regime.extended">
					<div class="zone__programs">
						<div class="zone__programs-title" v-if="false">Программы:</div>
						<div class="zone__programs-body" v-if="false">
							<template v-if="zone.programs.length === 0">отсутствуют</template>
							<template v-else>
								<div
									v-for="program in zone.programs"
									class="zone__program"
									:data-period="program.period"
									:data-type="program.type"
									:key="program.id"
								>
									<img class="zone__icon" :src="require(`../assets/images/${program.type}.svg`)" alt="" />
									<span class="zone__program-title">{{ program.title }}</span>
								</div>
							</template>
						</div>
						<Dropdown
							type="simple"
							:name="zone.regime.name"
							:items="zone.regime.list.categories"
							:value="zone.regime.list.value"
							@click.stop="handleZone($event, 'dropdown')"
						/>
						<div class="zone__regime" :data-id="zone.regime.id">
							<div class="zone__regime-channels">
								<div
									v-for="(channel, index) in zone.regime.channels"
									:class="
										zone.regime.channel === channel
											? 'zone__regime-channel zone__regime-channel_active'
											: 'zone__regime-channel'
									"
									:key="index"
									:data-channel="channel"
									@click="handleZone($event, 'tab')"
								>
									<div class="zone__regime-channel-name" v-if="channel === 'hum'">Влажность</div>
									<div class="zone__regime-channel-name" v-else-if="channel === 'temp'">Температура</div>
									<div class="zone__regime-channel-name" v-else-if="channel === 'carb'">СО2</div>
								</div>
							</div>
							<div class="zone__regime-body" :data-channel="zone.regime.channel">
								<div class="zone__regime-activity">
									<div class="zone__regime-headline">Активность ПРОГРАММЫ</div>
									<Switch
										:name="`regime_active_${zone.level}`"
										:status="zone.regime[`active_${zone.level}`]"
										:paint="
											zone.regime.channel === 'hum'
												? 'blue'
												: zone.regime.channel === 'temp'
												? 'orange'
												: 'dark'
										"
										@change="handleZone($event, 'switch', zone.regime.channel)"
									/>
								</div>
								<div class="zone__regime-schedule">
									<div class="zone__regime-headline">ГРАФИК ПРОГРАММЫ</div>
									<div class="zone__regime-periods">
										<div
											class="zone__regime-period"
											v-for="(period, index) in zone.regime.periods"
											:key="index"
											:data-period="period.name"
										>
											<div class="zone__regime-title">
												{{ period.title }}
											</div>
											<div class="zone__regime-holder">
												<div class="zone__regime-value">
													<Input
														:type="'tel'"
														:name="'period'"
														:value="period.set"
														:status="period.status"
														@input="handleZone($event, 'input', 'regime')"
													/>
												</div>
												<div class="zone__regime-range">
													<Timepicker
														:hour="convertProgramTime(period.start.hour, 'hour')"
														:minute="convertProgramTime(period.start.minute, 'minute')"
														:data-period="period.name"
														@click="handleZone($event, 'timepicker', 'range')"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="zone__regime-week">
									<div class="zone__regime-headline">ДНИ ПРОГРАММЫ</div>
									<div class="zone__regime-wrapper">
										<div class="zone__regime-days">
											<div class="zone__regime-day" v-for="(day, index) in zone.regime.days" :key="index">
												<Checkbox
													:status="day.activity"
													:data-day="day.num"
													@change="handleZone($event, 'checkbox', 'days')"
												/>
												<span class="zone__regime-day-name">{{ day.name }}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div class="zone__info">
				<div class="zone__caption">
					<div class="zone__caption-set">Целевое</div>
					<hr class="zone__caption-line" />
					<div class="zone__caption-cur">Текущее</div>
				</div>
				<div class="zone__indicators">
					<div class="zone__indicator" data-param="hum" v-if="zone.hum">
						<template v-if="zone.hum[`active_${zone.level}`]">
							<div class="zone__set">
								<font-awesome-icon icon="arrow-down" />
								<span class="zone__set-value">{{ zone.hum.set }}</span>
								<span class="zone__set-unit">%</span>
							</div>
							<div class="zone__cur">
								<font-awesome-icon icon="arrow-up" v-if="zone.hum.activity" />
								<font-awesome-icon icon="arrow-down" v-else />
								<span class="zone__cur-value">{{ zone.hum.cur }}</span>
								<span class="zone__cur-unit">%</span>
							</div>
						</template>
						<template v-else>
							<div class="zone__set">
								<span class="zone__set-dash">&mdash;</span>
								<span class="zone__set-dash">&mdash;</span>
								<span class="zone__set-unit">%</span>
							</div>
							<div class="zone__cur">
								<span class="zone__cur-value">{{ zone.hum.cur }}</span>
								<span class="zone__cur-unit">%</span>
							</div>
						</template>
						<span
							:class="zone.hum[`active_${zone.level}`] ? 'zone__led zone__led_on' : 'zone__led zone__led_off'"
						></span>
					</div>
					<div class="zone__indicator" data-param="temp" v-if="zone.temp">
						<template v-if="zone.temp[`active_${zone.level}`]">
							<div class="zone__set">
								<font-awesome-icon icon="arrow-down" />
								<span class="zone__set-value">{{ zone.temp.set }}</span>
								<span class="zone__set-unit">&#176;</span>
							</div>
							<div class="zone__cur">
								<font-awesome-icon icon="arrow-up" v-if="zone.temp.activity" />
								<font-awesome-icon icon="arrow-down" v-else />
								<span class="zone__cur-value">{{ zone.temp.cur }}</span>
								<span class="zone__cur-unit">&#176;</span>
							</div>
						</template>
						<template v-else>
							<div class="zone__set">
								<span class="zone__set-dash">&mdash;</span>
								<span class="zone__set-dash">&mdash;</span>
								<span class="zone__set-unit">&#176;</span>
							</div>
							<div class="zone__cur">
								<span class="zone__cur-value">{{ zone.temp.cur }}</span>
								<span class="zone__cur-unit">&#176;</span>
							</div>
						</template>
						<span
							:class="zone.temp[`active_${zone.level}`] ? 'zone__led zone__led_on' : 'zone__led zone__led_off'"
						></span>
					</div>
					<div class="zone__indicator" data-param="carb" v-if="zone.carb">
						<div class="zone__set">
							<span class="zone__set-value">{{ zone.carb.set }}</span>
							<span class="zone__set-unit">PPM</span>
						</div>
						<div class="zone__cur">
							<div class="zone__cur-wrapper">
								<font-awesome-icon
									icon="arrow-up"
									v-if="zone.carb[`active_${zone.level}`] && zone.carb.activity"
								/>
								<font-awesome-icon icon="arrow-down" v-else />
								<span class="zone__cur-value">{{ zone.carb.cur }}</span>
							</div>
							<span class="zone__cur-unit">PPM</span>
						</div>
						<span
							:class="zone.carb[`active_${zone.level}`] ? 'zone__led zone__led_on' : 'zone__led zone__led_off'"
						></span>
					</div>
				</div>
			</div>
		</div>
		<div class="zone__footer" v-show="zone.extended">
			<template v-if="Array.isArray(zone.errors) && zone.errors.length > 0">
				<div class="zone__errors">
					<div class="zone__error" v-for="(error, index) in zone.errors" :key="index" :data-error="error.code">
						<div class="zone__error-wrapper">
							<span class="zone__error-dot"></span>
							<div class="zone__error-info">
								<div class="zone__error-date">
									{{ convertDate(error.time, "full") }}
								</div>
								<div class="zone__error-text">{{ error.text }}</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<div class="zone__params">
				<div class="zone__param" data-param="hum" v-if="zone.hum">
					<div class="zone__wrapper">
						<Switch
							:paint="'blue'"
							:name="`active_${zone.level}`"
							:status="zone.hum[`active_${zone.level}`]"
							@change="handleZone($event, 'switch', 'hum')"
						/>
						<div class="zone__tuner">
							<div class="zone__set">
								<span class="zone__set-value">{{ zone.hum.set }}</span>
								<span class="zone__set-unit">%</span>
							</div>
							<Slider
								type="horizontal"
								:value="zone.hum.set"
								:min="zone.hum.min.value"
								:max="zone.hum.max.value"
								:name="'set'"
								:disabled="!zone.hum[`active_${zone.level}`]"
								@input="handleZone($event, 'slider', 'hum')"
								@change="handleZone($event, 'slider', 'hum')"
							/>
						</div>
						<img class="zone__icon" src="../assets/images/hum.svg" alt="" />
					</div>
					<div class="zone__limits" v-if="zone.level === 'srv'">
						<div class="zone__limit">
							<div class="zone__title">Минимальное</div>
							<Input
								:type="'text'"
								:name="zone.hum.min.name"
								:value="zone.hum.min.value"
								:status="zone.hum.min.status"
								data-param="hum"
								@input="handleZone($event, 'input')"
								@change="handleZone($event, 'input')"
							/>
						</div>
						<div class="zone__limit">
							<div class="zone__title">Максимальное</div>
							<Input
								:type="'text'"
								:name="zone.hum.max.name"
								:value="zone.hum.max.value"
								:status="zone.hum.max.status"
								data-param="hum"
								@input="handleZone($event, 'input')"
								@change="handleZone($event, 'input')"
							/>
						</div>
						<div class="zone__limit">
							<div class="zone__title">Гистерезис управления</div>
							<Input
								:type="'text'"
								:name="zone.hum.gys.name"
								:value="zone.hum.gys.value"
								:status="zone.hum.gys.status"
								data-param="hum"
								@input="handleZone($event, 'input')"
								@change="handleZone($event, 'input')"
							/>
						</div>
					</div>
					<div class="zone__limits" v-if="zone.level === 'srv' && zone.system_architecture === 'Кольцевая'">
						<div class="zone__limit">
							<div class="zone__title">Длительность работы</div>
							<Input
								:type="'text'"
								:name="zone.hum.t_open.name"
								:value="zone.hum.t_open.value"
								:status="zone.hum.t_open.status"
								data-param="t_open"
								@input="handleZone($event, 'input')"
								@change="handleZone($event, 'input')"
							/>
						</div>
						<div class="zone__limit">
							<div class="zone__title">Длительность паузы</div>
							<Input
								:type="'text'"
								:name="zone.hum.t_close.name"
								:value="zone.hum.t_close.value"
								:status="zone.hum.t_close.status"
								data-param="t_close"
								@input="handleZone($event, 'input')"
								@change="handleZone($event, 'input')"
							/>
						</div>
					</div>
				</div>
				<div class="zone__param" data-param="temp" v-if="zone.temp">
					<div class="zone__wrapper">
						<Switch
							:paint="'orange'"
							:name="`active_${zone.level}`"
							:status="zone.temp[`active_${zone.level}`]"
							@change="handleZone($event, 'switch', 'temp')"
						/>
						<div class="zone__tuner">
							<div class="zone__set">
								<span class="zone__set-value">{{ zone.temp.set }}</span>
								<span class="zone__set-unit">&#176;</span>
							</div>
							<Slider
								type="horizontal"
								:value="zone.temp.set"
								:min="zone.temp.min.value"
								:max="zone.temp.max.value"
								:name="'set'"
								:disabled="!zone.temp[`active_${zone.level}`]"
								@input="handleZone($event, 'slider', 'temp')"
								@change="handleZone($event, 'slider', 'temp')"
							/>
						</div>
						<img class="zone__icon" src="../assets/images/temp.svg" alt="" />
					</div>
					<div class="zone__limits" v-if="zone.level === 'srv'">
						<div class="zone__limit">
							<div class="zone__title">Минимальное</div>
							<Input
								:type="'text'"
								:name="zone.temp.min.name"
								:value="zone.temp.min.value"
								:status="zone.temp.min.status"
								data-param="temp"
								@input="handleZone($event, 'input')"
								@change="handleZone($event, 'input')"
							/>
						</div>
						<div class="zone__limit">
							<div class="zone__title">Максимальное</div>
							<Input
								:type="'text'"
								:name="zone.temp.max.name"
								:value="zone.temp.max.value"
								:status="zone.temp.max.status"
								data-param="temp"
								@input="handleZone($event, 'input')"
								@change="handleZone($event, 'input')"
							/>
						</div>
						<div class="zone__limit">
							<div class="zone__title">Гистерезис управления</div>
							<Input
								:type="'text'"
								:name="zone.temp.gys.name"
								:value="zone.temp.gys.value"
								:status="zone.temp.gys.status"
								data-param="temp"
								@input="handleZone($event, 'input')"
								@change="handleZone($event, 'input')"
							/>
						</div>
					</div>
				</div>
				<div class="zone__param" data-param="carb" v-if="zone.carb">
					<div class="zone__wrapper">
						<Switch
							:paint="'dark'"
							:name="`active_${zone.level}`"
							:status="zone.carb[`active_${zone.level}`]"
							@change="handleZone($event, 'switch', 'carb')"
						/>
						<div class="zone__tuner">
							<div class="zone__set">
								<span class="zone__set-value">{{ zone.carb.set }}</span>
								<span class="zone__set-unit">PPM</span>
							</div>
							<Slider
								type="horizontal"
								:value="zone.carb.set"
								:min="zone.carb.min.value"
								:max="zone.carb.max.value"
								:name="'set'"
								:disabled="!zone.carb[`active_${zone.level}`]"
								@input="handleZone($event, 'slider', 'carb')"
								@change="handleZone($event, 'slider', 'carb')"
							/>
						</div>
						<img class="zone__icon" src="../assets/images/carb.svg" alt="" />
					</div>
					<div class="zone__limits" v-if="zone.level === 'srv'">
						<div class="zone__limit">
							<div class="zone__title">Минимальное</div>
							<Input
								:type="'text'"
								:name="zone.carb.min.name"
								:value="zone.carb.min.value"
								:status="zone.carb.min.status"
								data-param="carb"
								@input="handleZone($event, 'input')"
								@change="handleZone($event, 'input')"
							/>
						</div>
						<div class="zone__limit">
							<div class="zone__title">Максимальное</div>
							<Input
								:type="'text'"
								:name="zone.carb.max.name"
								:value="zone.carb.max.value"
								:status="zone.carb.max.status"
								data-param="carb"
								@input="handleZone($event, 'input')"
								@change="handleZone($event, 'input')"
							/>
						</div>
						<div class="zone__limit">
							<div class="zone__title">Гистерезис управления</div>
							<Input
								:type="'text'"
								:name="zone.carb.gys.name"
								:value="zone.carb.gys.value"
								:status="zone.carb.gys.status"
								data-param="carb"
								@input="handleZone($event, 'input')"
								@change="handleZone($event, 'input')"
							/>
						</div>
					</div>
				</div>
			</div>
			<template v-if="zone.level === 'srv' && zone.hum">
				<div class="zone__block" data-block="devs">
					<div class="zone__subblock" data-part="first">
						<div class="zone__title">Тип устройства</div>
						<Dropdown
							:type="'simple'"
							:items="zone.device.dropdown.categories"
							:name="zone.device.dropdown.name"
							:value="zone.device.dropdown.value"
							:status="zone.device.dropdown.status"
							@click="handleZone($event, 'dropdown')"
						/>
					</div>
					<div class="zone__subblock" data-part="last">
						<div class="zone__title">Адрес</div>
						<Input
							:type="'text'"
							:name="zone.device.input.name"
							:value="zone.device.input.value"
							:status="zone.device.input.status"
							@input="handleZone($event, 'input')"
							@change="handleZone($event, 'input')"
						/>
					</div>
					<Button
						:type="'action'"
						:value="'add'"
						:paint="'gray'"
						:disabled="zone.device.button.disabled"
						@click.stop="handleZone($event, 'add')"
					/>
				</div>
				<div class="zone__modules" v-if="zone.devs.length > 0" data-modules="devs">
					<div class="zone__module" v-for="(elem, index) in zone.devs" :data-id="index" :key="index">
						<div class="zone__subblock">
							<Input :type="'text'" v-if="elem.type === 's'" value="Датчик" :disabled="true" />
							<Input
								:type="'text'"
								v-else-if="elem.type === 'gt'"
								value="Гигростат с тачсрином"
								:disabled="true"
							/>
							<Input
								:type="'text'"
								v-else-if="elem.type === 'gw'"
								value="Гигростат с колесом"
								:disabled="true"
							/>
						</div>
						<div class="zone__subblock">
							<Input :type="'text'" :value="elem.addr" :disabled="true" />
						</div>
						<Button
							:type="'action'"
							:value="'delete'"
							:paint="'gray'"
							@click.stop="handleZone($event, 'delete')"
						/>
					</div>
				</div>
				<div class="zone__block" data-block="nozzles">
					<div class="zone__subblock" data-part="first">
						<div class="zone__title">Тип форсунок</div>
						<Dropdown
							:type="'simple'"
							:items="zone.nozzle.dropdown.categories"
							:name="zone.nozzle.dropdown.name"
							:value="zone.nozzle.dropdown.value"
							:status="zone.nozzle.dropdown.status"
							@click="handleZone($event, 'dropdown')"
						/>
					</div>
					<div class="zone__subblock" data-part="last">
						<div class="zone__title">Количество</div>
						<Input
							:type="'text'"
							:name="zone.nozzle.input.name"
							:value="zone.nozzle.input.value"
							:status="zone.nozzle.input.status"
							@input="handleZone($event, 'input')"
							@change="handleZone($event, 'input')"
						/>
					</div>
					<Button
						:type="'action'"
						:value="'add'"
						:paint="'gray'"
						:disabled="zone.nozzle.button.disabled"
						@click.stop="handleZone($event, 'add')"
					/>
				</div>
				<div class="zone__modules" v-if="zone.nozzles.length > 0" data-modules="nozzles">
					<div class="zone__module" v-for="(elem, index) in zone.nozzles" :data-id="index" :key="index">
						<div class="zone__subblock">
							<Input :type="'text'" :value="elem.type" :disabled="true" />
						</div>
						<div class="zone__subblock">
							<Input :type="'text'" :value="elem.quan" :disabled="true" />
						</div>
						<Button
							:type="'action'"
							:value="'delete'"
							:paint="'gray'"
							@click.stop="handleZone($event, 'delete')"
						/>
					</div>
				</div>
			</template>
			<div class="zone__control">
				<Button
					:type="'text'"
					:value="'Отмена'"
					:paint="'gray'"
					:disabled="zone.cancellation"
					@click="cancelChanges($event, 'zone')"
				/>
				<Button
					:type="'text'"
					:value="'Сохранить'"
					:paint="'blue'"
					:disabled="!zone.validation"
					@click="applyZone"
				/>
			</div>
		</div>
		<div class="zone__arrow" @click.stop="collapseZone($event, zone.extended)">
			<Chevron :orient="zone.extended ? 'up' : 'down'" />
		</div>
	</div>
</template>

<script>
import Button from "../components/Button";
import Checkbox from "../components/Checkbox";
import Chevron from "../components/Chevron";
import Dropdown from "../components/Dropdown";
import Input from "../components/Input";
import Slider from "../components/Slider";
import Switch from "../components/Switch";
import Timepicker from "../components/Timepicker";

export default {
	name: "Zone",
	components: {
		Button,
		Checkbox,
		Chevron,
		Dropdown,
		Input,
		Slider,
		Switch,
		Timepicker,
	},
	props: {
		zone: Object,
	},
	methods: {
		convertDate(timestamp) {
			if (timestamp !== null) {
				let newDate = new Date(timestamp * 1000);
				let year = newDate.getFullYear();
				let month = newDate.getMonth() + 1;
				if (month < 10) month = "0" + month;
				let day = newDate.getDate();
				if (day < 10) day = "0" + day;
				let date = `${day}.${month}.${year}`;
				return date;
			}
		},
		convertProgramTime(date, param) {
			if (param === "day") {
				let days = ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"];
				let day = days[date];

				return day;
			} else {
				if (date < 10) date = "0" + date;

				return date;
			}
		},
		handleZone(event, element, param) {
			let payload = {};
			//TODO: Пересмотреть!!!
			let system = event.target.closest(".system");
			let objects = event.target.closest(".objects");

			payload.event = event.type;

			if (element === "input") {
				if (param === "regime") {
					payload.name = event.target.getAttribute("name");
					payload.period = event.target.closest(".zone__regime-period").getAttribute("data-period");
					payload.channel = event.target.closest(".zone__regime-body").getAttribute("data-channel");
					payload.regime = +event.target.closest(".zone__regime").getAttribute("data-id");
				} else {
					payload.param = event.target.getAttribute("data-param");
					payload.name = event.target.getAttribute("name");
				}

				payload.value = event.target.value;
			}

			if (element === "switch") {
				payload.value = event.target.checked;
				payload.name = event.target.getAttribute("name");
				payload.param = param;
				//TODO: Пересмотреть!!!
				if (payload.name === "regime_active_srv" || payload.name === "regime_active_user") {
					payload.regime = +event.target.closest(".zone__regime").getAttribute("data-id");
				}
			}

			if (element === "tab") {
				payload.channel = event.target.closest(".zone__regime-channel").getAttribute("data-channel");
				payload.regime = +event.target.closest(".zone__regime").getAttribute("data-id");
			}

			if (element === "timepicker") {
				if (event.target.closest(".timepicker__item")) {
					payload = {
						event: "change",
						period: event.target.closest(".timepicker").getAttribute("data-period"),
						unit: event.target.closest(".timepicker__list").getAttribute("data-unit"),
						value: parseInt(event.target.closest(".timepicker__item").innerHTML),
						regime: +event.target.closest(".zone__regime").getAttribute("data-id"),
					};
				}
			}

			if (element === "checkbox") {
				payload = {
					event: "change",
					day: +event.target.closest(".checkbox").getAttribute("data-day"),
					value: event.target.checked,
					regime: +event.target.closest(".zone__regime").getAttribute("data-id"),
				};
			}

			if (element === "slider") {
				payload.value = +event.target.value;
				payload.name = event.target.getAttribute("name");
				payload.param = param;
			}

         if (element === "dropdown") {
				if (event.target.closest(".dropdown").getAttribute("name") === "num") {
					if (event.target.closest(".dropdown__button") || event.target.closest(".dropdown__field")) {
						payload = {
							event: "change",
							open: true,
							name: "num",
						};
					} else if (event.target.closest(".dropdown__item")) {
						payload = {
							name: event.target.closest(".dropdown").getAttribute("name"),
							alias: event.target.closest(".dropdown__item").getAttribute("data-name"),
							value: event.target.closest(".dropdown__item").querySelector(".dropdown__title").innerHTML,
							event: "change",
						};
					} else return false;
				} else {
					if (event.target.closest(".dropdown__item")) {
						payload = {
							name: event.target.closest(".dropdown").getAttribute("name"),
							alias: event.target.closest(".dropdown__item").getAttribute("data-name"),
							itemID: +event.target.closest(".dropdown__item").getAttribute("data-id"),
							value: event.target.closest(".dropdown__item").querySelector(".dropdown__title").innerHTML,
							event: "change",
						};
					} else return false;
				}
			}

			if (element === "add") {
				payload = {
					block: event.target.closest(".zone__block").getAttribute("data-block"),
					event: "change",
				};
			}

			if (element === "delete") {
				payload = {
					modules: event.target.closest(".zone__modules").getAttribute("data-modules"),
					index: +event.target.closest(".zone__module").getAttribute("data-id"),
					event: "change",
				};
			}

			if (element === "section") {
				payload.section = true;
			}

			payload.id = +event.target.closest(".zone").getAttribute("data-id");
			payload.element = element;
			payload.system = system != null ? +system.getAttribute("data-id") : +objects.getAttribute("data-id");

			this.$emit(`zn${payload.event}`, payload);
		},
		collapseZone(event, extended) {
			let payload = {
				id: +event.target.closest(".zone").getAttribute("data-id"),
				extended: extended,
			};

			this.$emit("zncollapse", payload);
		},
		applyZone(event) {
			//TODO: Пересмотреть!!!
			let system = event.target.closest(".system");
			let objects = event.target.closest(".objects");

			let payload = {
				zone: +event.target.closest(".zone").getAttribute("data-id"),
				system: system != null ? +system.getAttribute("data-id") : +objects.getAttribute("data-id"),
			};

			this.$emit("znapply", payload);
		},
		cancelChanges(event, param) {
			let payload = {
				id: +event.target.closest(".zone").getAttribute("data-id"),
				param: param,
			};

			this.$emit("zncancel", payload);
		},
	},
};
</script>

<style lang="scss">
.zone {
	@include base;

	width: 100%;
	border: 2px solid $colorMainLight;
	padding: 10px;
	margin-bottom: 10px;
	cursor: pointer;

	&:last-of-type {
		margin-bottom: 0;
	}

	@include mediaquery(md) {
		width: calc(50% - 10px);
		margin: 5px;
	}

	@include mediaquery(xl) {
		width: calc(33.333333% - 10px);
	}

	@include mediaquery(hg) {
		width: calc(25% - 10px);
	}

	&_current {
		border: 2px solid #41c6eb;
	}

	&__body {
		position: relative;
	}

	&__motion {
		position: absolute;
		right: 3px;
		top: 3px;
		font-size: 20px;
		color: #ff8068;
	}

	&__name {
		color: #222325;
		display: flex;
		justify-content: center;
		margin-bottom: 5px;

		&-input {
			border: 1px solid transparent;
			border-radius: 6px;
			background: none;
			font-family: inherit;
			text-transform: uppercase;
			font-weight: 500;
			font-size: 14px;
			text-align: center;
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding: 5px 30px;

			&:hover {
				border: 1px solid #dcdcdc;
			}

			&:focus {
				border: 1px solid #41c6eb;
			}

			&_valid {
				border-color: #41c6eb;
			}

			&_invalid {
				border-color: #ff8068 !important;
			}
		}
	}

	&__prog {
		@include base;

		margin-top: 10px;

		&-header {
			padding: 15px;
			display: flex;
			justify-content: space-between;
		}

		&-title {
			text-transform: uppercase;
			font-weight: 500;
			font-size: 12px;
		}

		&-control {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-left: 10px;
			width: 15%;
		}

		&-button {
			position: relative;
			background: none;
			padding: 0;
			border: 0;
			height: 11px;
			width: 11px;
			cursor: pointer;
			margin-left: 10px;

			&-icon {
				position: absolute;
				left: 2px;
				top: 0px;
				width: 7px;
				height: 7px;
				border: solid #bcbcbc;
				border-width: 0 2px 2px 0;
				transform: rotate(45deg);

				&_rotate {
					border-color: #222325;
					top: 3px;
					transform: rotate(225deg);
				}
			}
		}

		&-body {
			padding: 0 15px 15px;
		}
	}

	&__programs {
		display: flex;
		flex-direction: column;
		align-items: center;

		&-title {
			font-size: 14px;
			font-weight: 500;
			text-transform: uppercase;
			margin-bottom: 10px;
		}

		&-body {
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 500;
			margin-left: 5px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			min-height: 20px;
		}

		.zone__icon {
			width: 20px;
			margin: 0 5px 0 0;
		}
	}

	&__program {
		display: flex;
		align-items: center;
		margin: 0 5px;
		font-size: 14px;
		text-transform: uppercase;

		&:first-of-type {
			margin-left: 0;
		}

		&:last-of-type {
			margin-right: 0;
		}

		&[data-type="hum"] {
			.zone__program-title {
				color: $colorHum;
			}
		}

		&[data-type="temp"] {
			.zone__program-title {
				color: $colorTemp;
			}
		}

		&[data-type="carb"] {
			.zone__program-title {
				color: $colorCarb;
			}
		}
	}

	&__regime {
		width: 100%;
		margin-top: 15px;

		&-channels {
			display: flex;
			width: 100%;
			border-bottom: 1px solid #dcdcdc;
		}

		&-channel {
			width: 33.333333%;
			background-color: $colorMainLight;
			padding: 5px;
			display: flex;
			align-items: center;
			cursor: pointer;

			&_active {
				border: 1px solid #dcdcdc;
				border-top-left-radius: 6px;
				border-top-right-radius: 6px;
				border-bottom-color: transparent;
				margin-bottom: -1px;

				.zone__regime-channel-name {
					margin-bottom: 1px;
				}
			}

			&-name {
				width: 100%;
				text-align: center;
			}
		}

		&-body {
			padding: 10px;
			border: 1px solid #dcdcdc;
			border-top: 0;
			border-radius: 6px;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}

		&-headline {
			font-size: 12px;
			color: #222325;
			text-transform: uppercase;
		}

		&-activity {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;
		}

		&-wrapper {
			margin-top: 12px;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}

		&-schedule {
			margin-bottom: 10px;
		}

		&-periods {
			margin-top: 12px;
		}

		&-period {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;

			&[data-period="morning"] {
				.timepicker {
					background-color: #8bd341;
				}
			}

			&[data-period="day"] {
				.timepicker {
					background-color: #ff6eda;
				}
			}

			&[data-period="evening"] {
				.timepicker {
					background-color: #7976ff;
				}
			}

			&[data-period="night"] {
				.timepicker {
					background-color: #3a95ff;
				}
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		&-title {
			font-size: 12px;
			font-weight: 500;
			min-width: 40px;
		}

		&-holder {
			display: flex;
			flex-grow: 1;
			justify-content: flex-end;
			width: 70%;
		}

		&-value {
			display: flex;
			justify-content: space-between;
			margin: 0 10px;

			input {
				padding: 5px;
				height: 29px !important;
				margin-right: 10px;
				text-align: center;
				flex-grow: 1;
				width: calc(33.3333% - 5px);

				&:last-of-type {
					margin-right: 0;
				}
			}
		}

		&-range {
			display: flex;

			.timepicker {
				color: #fff;
			}
		}

		&-days {
			display: flex;
		}

		&-day {
			display: flex;
			flex-direction: column;
			margin-right: 10px;
			text-align: center;

			&:last-of-type {
				margin-right: 0;
			}

			&-name {
				margin-top: 7px;
				font-size: 12px;
			}
		}

		&-icon {
			width: 26px;
			height: 26px;
			margin-top: 2px;
		}
	}

	&__info {
		display: flex;
		margin-top: 10px;
	}

	&__caption {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-right: 15px;
		color: #363636;
		font-size: 11px;
		font-weight: 500;

		&-line {
			background: #363636;
			border: 0;
			width: 100%;
			height: 1px;
			margin: 7px 0;
		}
	}

	&__indicators {
		display: flex;
		flex-grow: 1;
		justify-content: space-between;
	}

	&__indicator {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		border-radius: 6px;
		box-shadow: $shadowLight;
		color: #fff;
		padding: 5px 10px;
		flex-grow: 1;
		width: calc(33.333333% - 10px);
		min-width: calc(33.333333% - 10px);
		min-height: 75px;

		&:not(:last-of-type) {
			margin-right: 10px;
		}

		&[data-param="temp"] {
			background-color: $colorTemp;
		}

		&[data-param="hum"] {
			background-color: $colorHum;
		}

		&[data-param="carb"] {
			background-color: $colorCarb;

			.zone__set,
			.zone__cur {
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				align-items: center;
				margin-bottom: 0;
			}

			.zone__set-unit,
			.zone__cur-unit {
				font-size: 7px;
				margin-top: -3px;
			}
		}
	}

	&__set {
		font-size: 19px;
		font-weight: 500;
		margin-top: 6px;
		white-space: nowrap;

		svg {
			visibility: hidden;
		}

		&-dash {
			font-weight: 400;

			&:not(:first-of-type) {
				margin-left: 3px;
			}

			~ .zone__set-unit {
				visibility: hidden;
			}
		}
	}

	&__cur {
		display: flex;
		align-items: center;
		font-size: 19px;
		font-weight: 500;
		margin-bottom: 6px;

		&-wrapper {
			white-space: nowrap;
		}

		&-value {
			margin-left: 2px;
		}
	}

	&__led {
		position: absolute;
		width: 5px;
		height: 5px;
		border-radius: 50%;
		top: 5px;
		right: 5px;

		&_on {
			background: linear-gradient(212.01deg, #dfffbe 13.85%, #9eff3d 82.31%);
		}

		&_off {
			background: linear-gradient(208.61deg, #fc4f4f 0.59%, #ce0909 92.94%);
			background: none;
		}
	}

	&__errors {
		display: flex;
		flex-wrap: wrap;
		margin-top: 15px;
		margin-bottom: 10px;
	}

	&__error {
		@include base;

		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
		width: 100%;

		&:last-of-type {
			margin-bottom: 0;
		}

		&-wrapper {
			display: flex;
			align-items: center;
		}

		&-dot {
			background-color: #ff5e3f;
			width: 4px;
			height: 4px;
			display: inline-block;
			border-radius: 50%;
			margin-right: 9px;
		}

		&-date {
			font-size: 9px;
			color: #000000;
		}

		&-text {
			font-size: 12px;
			color: #ff5e3f;
			text-transform: uppercase;
		}
	}

	&__footer {
		display: flex;
		flex-direction: column;
		margin-top: 10px;
	}

	&__params {
		display: flex;
		flex-direction: column;
	}

	&__param {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 10px;
		padding: 10px;
		border: 2px solid transparent;
		border-radius: 6px;

		&[data-param="hum"] {
			border-color: $colorHum;

			.zone__set {
				color: $colorHum;
			}
		}

		&[data-param="temp"] {
			border-color: $colorTemp;

			.zone__set {
				color: $colorTemp;
			}
		}

		&[data-param="carb"] {
			border-color: $colorCarb;

			.zone__set {
				color: $colorCarb;
			}
		}
	}

	&__wrapper {
		width: 100%;
		display: flex;
		align-items: flex-end;
		margin-bottom: 10px;
	}

	&__limits {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 10px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&__limit {
		width: calc(50% - 5px);
		margin-top: 5px;
	}

	&__tuner {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		align-items: flex-start;
		margin-left: 10px;

		.slider {
			width: 100%;
			margin: 4px 0;
		}
	}

	&__icon {
		width: 28px;
		margin-left: 10px;
		margin-bottom: -3px;
	}

	&__block {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		flex-wrap: wrap;
		position: relative;
		margin-bottom: 10px;

		&:not(:first-of-type) {
			margin-top: 10px;
		}
	}

	&__modules {
		display: flex;
		flex-wrap: wrap;
	}

	&__module {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 10px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&__title {
		@include title;
	}

	&__subblock {
		position: relative;

		&:first-of-type {
			width: calc(50% - 5px);
		}
		&:last-of-type {
			width: calc(50% - 51px);
		}
	}

	&__control {
		display: flex;
		flex-direction: column;
		margin-top: 10px;

		@include mediaquery(sm) {
			flex-direction: row;
			justify-content: space-between;
		}

		.button {
			width: 100%;

			&:first-of-type {
				margin-bottom: 10px;
			}

			@include mediaquery(sm) {
				width: calc(50% - 5px);

				&:first-of-type {
					margin-bottom: 0px;
				}
			}
		}
	}

	&__arrow {
		display: flex;
		justify-content: center;
		margin-top: 15px;
	}
}
</style>
